import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useRouter } from '../hooks/useRouter';
import * as ROUTES from '../constants/routes';
import Balance from '../components/Balance';
import UnpaidCapsules from '../components/UnpaidCapsules';
import ActivitySidebar from '../components/ActivityBar';
import LeftSidebar from '../components/SidebarMenu';
import LanguageDialog from '../components/LanguageDialog';
import PromotionDialog from '../components/PromotionDialog';
import { useApi } from '../hooks/useApi';
import { Hidden } from '@material-ui/core';
import MobileAppBar from '../components/MobileAppBar';
import LeftSidebarMobile from '../components/LeftSidebarMobile';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // [theme.breakpoints.down('sm')]: {
    //   display: 'block',
    // },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar: {
    width: `calc(100% - 72px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#FFF',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    width: '100%',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#F4F8FC',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const MainLayout = (props) => {
  const classes = useStyles();
  const auth = useAuth();
  const api = useApi();
  const router = useRouter();
  const { i18n, t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [prices, setPrices] = useState({});
  const [isDrawerOpened, setDrawerOpened] = useState(window.localStorage.getItem('sidebarOpened') === 'true');
  const [isActivityOpened, setActivityOpened] = useState(false);
  const [language, setLanguage] = useState(window.localStorage.getItem('lang') || 'en');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isPromoShown, setPromoShown] = useState(true);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      router.history.push(ROUTES.AUTH_SIGN_IN);
    });
  };

  const getPrices = () => {
    setLoading(true);
    api
      .getPrices()
      .then((response) => {
        setPrices(response.data);
      })
      .finally(() => setLoading(false));
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    setLanguage(language);
    window.localStorage.setItem('lang', language);
  };

  const handleDrawerToggle = () => {
    window.localStorage.setItem('sidebarOpened', String(!isDrawerOpened));
    setDrawerOpened(!isDrawerOpened);
  };

  const handleActivityToggle = () => {
    setActivityOpened(!isActivityOpened);
  };

  useEffect(() => {
    document.title = 'Dashboard - SaveToFuture';
    i18n.changeLanguage(language).then(() => {});
  }, [i18n, language]);

  useEffect(() => {
    getPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initUser = () => {
    api.getUser().then((response) => {
      if (response.data.data?.isPromoShown) {
        setPromoShown(true);
      } else {
        setPromoShown(false);
      }
    });
  };

  useEffect(() => {
    if (auth.isReady) {
      initUser();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Hidden smDown>
        <AppBar
          position="absolute"
          color="default"
          elevation={1}
          className={clsx(classes.appBar, isDrawerOpened && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <Box display="flex">
              <Box>
                <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
                  {isDrawerOpened ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
              </Box>

              <Box display="flex" alignItems="center">
                <Box ml={1} mr={1}>
                  <Balance />
                </Box>

                <Box ml={1} mr={1}>
                  <UnpaidCapsules />
                </Box>
              </Box>

              {isLoading || isPromoShown ? null : <PromotionDialog prices={prices} />}
            </Box>

            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <LanguageDialog language={language} onChange={handleLanguageChange} />
              </Box>

              <IconButton color="inherit" onClick={handleActivityToggle}>
                <InfoOutlinedIcon />
              </IconButton>

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <PersonOutlineOutlinedIcon />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to="/profile">
                  {t('translation:header:profile')}
                </MenuItem>
                <MenuItem onClick={handleSignOut}>{t('translation:header:logout')}</MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden mdUp>
        <LeftSidebarMobile isDrawerOpened={isDrawerOpened} setDrawerOpened={setDrawerOpened} />
      </Hidden>

      <Hidden mdUp>
        <MobileAppBar
          toggleSidebar={handleDrawerToggle}
          language={language}
          handleActivityToggle={handleActivityToggle}
          handleLanguageChange={handleLanguageChange}
        />
        {isLoading || isPromoShown ? null : <PromotionDialog prices={prices} />}
      </Hidden>

      <Hidden smDown>
        <LeftSidebar isDrawerOpened={isDrawerOpened} />
      </Hidden>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {props.container}
      </main>

      <Hidden smDown>
        <ActivitySidebar mobile={false} isActivityOpened={isActivityOpened} />
      </Hidden>

      <Hidden mdUp>
        <ActivitySidebar mobile={true} isActivityOpened={isActivityOpened} />
      </Hidden>
    </div>
  );
};

export default MainLayout;
