import React, { Fragment } from 'react';

import { useRouter } from '../../hooks/useRouter';

import SignIn from './SignIn';
import SignUp from './SignUp';
import VerifyEmail from './VerifyEmail';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerifyPlease from './VerifyPlease';

/**
 * Authentication routing component
 * @returns {JSX.Element}
 * @constructor
 */
const Authentication = () => {
  const router = useRouter();

  return (
    <Fragment>
      {router.query?.mode === 'signIn' ? <SignIn /> : null}
      {router.query?.mode === 'signUp' ? <SignUp /> : null}
      {router.query?.mode === 'verifyPlease' ? <VerifyPlease /> : null}
      {router.query?.mode === 'verifyEmail' ? <VerifyEmail /> : null}
      {router.query?.mode === 'forgotPassword' ? <ForgotPassword /> : null}
      {router.query?.mode === 'resetPassword' ? <ResetPassword /> : null}
    </Fragment>
  );
};

export default Authentication;
