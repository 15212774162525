import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import promoTopBg from '../assets/promo-top-bg.svg';
import promoBottomBg from '../assets/promo-bottom-bg.svg';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useApi } from '../hooks/useApi';

const DialogTitleStyled = withStyles({
  root: {
    padding: 16,
    paddingBottom: 56,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundImage: `url(${promoTopBg})`,
  },
})(DialogTitle);

const DialogContentStyled = withStyles({
  root: {
    padding: 0,
    paddingBottom: 16,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30%',
    backgroundPosition: 'bottom right',
    backgroundImage: `url(${promoBottomBg})`,
  },
})(DialogContent);

const PromotionDialog = (props) => {
  const { t } = useTranslation();
  const api = useApi();
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const setUserData = () => {
    api
      .setUserData({
        key: 'isPromoShown',
        value: 'true',
      })
      .then(() => {});
  };

  const handleClose = () => {
    setUserData();
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitleStyled>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitleStyled>

      <DialogContentStyled>
        <Box display="flex" justifyContent="center">
          <Box textAlign="center" maxWidth={440} mt={2} p={2}>
            <Typography component="div">
              <Box fontSize={22} fontWeight={600} mb={2}>
                {t('translation:promo:title')}
              </Box>
            </Typography>

            <Typography component="div">
              <Box fontSize={16} mb={2}>
                {t('translation:promo:p1')}
              </Box>
            </Typography>

            <Typography component="span">
              <Box fontSize={16} mb={2}>
                {t('translation:promo:p2')}
                <Typography component="span">
                  <Box fontWeight={600}>{props.prices.new_user_balance} US$</Box>
                </Typography>
              </Box>
            </Typography>

            <Typography component="div">
              <Box fontSize={16} mb={8}>
                {t('translation:promo:p3')}
              </Box>
            </Typography>

            <Box mb={6}>
              <Button variant="contained" color="primary" size="large" onClick={handleClose}>
                {t('translation:promo:getButtonLabel')}
              </Button>
            </Box>

            <Box>
              <RouterLink component={Link} to={ROUTES.USER_TERMS_OF_SERVICE}>
                {t('translation:promo:termsLink')}
              </RouterLink>
            </Box>
          </Box>
        </Box>
      </DialogContentStyled>
    </Dialog>
  );
};

export default PromotionDialog;
