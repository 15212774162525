import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import capsuleUnpaid from '../assets/capsule-unpaid.png';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  label: { fontSize: 16, marginRight: 8, marginLeft: 8 },
  labelMobile: { fontSize: 14, color: '#FFFFFF' },
  icon: { width: 38 },
  iconMobile: { width: 24, marginRight: 10 },
}));

const UnpaidCapsules = (props) => {
  const classes = useStyles();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [count, setCount] = useState(null);

  const init = () => {
    api.getCapsulesCount().then((response) => {
      if (response.data['N']) {
        const unpaidCapsules = response.data['N'];
        setCount(unpaidCapsules);
      }
    });
  };

  useEffect(() => {
    if (auth.isReady) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return count ? (
    <Box display="flex" alignItems="center">
      <img src={capsuleUnpaid} alt={capsuleUnpaid} className={clsx(classes.icon, props.device && classes.iconMobile)} />

      <Typography className={clsx(classes.label, props.device && classes.labelMobile)}>
        {t('translation:header:unpaidCapsules', {
          count: count,
        })}
      </Typography>
    </Box>
  ) : null;
};

export default UnpaidCapsules;
