import React, { useEffect, useState } from 'react';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import { Typography, Box } from '@material-ui/core';
import { useApi } from '../hooks/useApi';
import { useAuth } from '../hooks/useAuth';
import { useSteps } from '../hooks/useSteps';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: { fontSize: 16, marginRight: 8, marginLeft: 8 },
  value: { fontSize: 20, fontWeight: 600 },
  mobile: { color: '#FFFFFF', marginRight: 8 },
  labelMobile: { color: '#FFFFFF', fontSize: 14 },
  valueMobile: { color: '#FFFFFF', fontSize: 16, fontWeight: 600 },
}));

const Balance = (props) => {
  const api = useApi();
  const auth = useAuth();
  const steps = useSteps();
  const [userInfo, setUserInfo] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const init = () => {
    api.getUser().then((response) => {
      setUserInfo(response.data);
    });
  };

  useEffect(() => {
    if (auth.isReady) {
      init();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, steps]);

  return userInfo ? (
    <Box display="flex" alignItems="center">
      <AccountBalanceWalletOutlinedIcon className={props.device && classes.mobile} />

      <Typography className={clsx(classes.label, props.device && classes.labelMobile)}>
        {t('translation:header:balanceWidget')}{' '}
      </Typography>

      <Typography className={clsx(classes.value, props.device && classes.valueMobile)}>
        {Number(userInfo?.balance).toFixed(2)} {'US$'}
      </Typography>
    </Box>
  ) : null;
};

export default Balance;
