import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2E3542',
        fontSize: 12,
        color: '#FFF',
      },
    },
    MuiStepIcon: {
      root: {
        fontSize: 28,
        color: '#FFF',
        '&$active': {
          color: '#2E3542',
        },
        '&$active text': {
          fill: '#FFF',
        },
      },
      active: {
        color: '#FFF',
      },
      text: {
        fill: '#2E3542',
        fontWeight: 500,
        fontSize: 14,
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#E41139',
        '&:hover': {
          backgroundColor: '#D70030',
        },
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '1px solid #E7EBF1',
        },
      },
      input: {
        '&::placeholder': {
          color: '#717171',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#C1C4C9',
      },
    },
  },
  typography: {
    h4: {
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    body2: {
      fontWeight: 300,
    },
  },
  palette: {
    text: {
      primary: '#2E3542',
    },
    primary: {
      main: '#2E3542',
    },
    background: {
      default: '#FFFFFF',
    },
    success: {
      main: '#80D948',
    },
  },
});

export default theme;
