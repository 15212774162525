import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import { useSteps } from '../../hooks/useSteps';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ChipInput from 'material-ui-chip-input';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  input: {
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const ActionAddressesRecipientsForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [emailAddresses, setEmailAddresses] = useState(capsule.addrEmail.length ? capsule.addrEmail.split(',') : []);
  const [telegramUsers, setTelegramUsers] = useState(capsule.addrTelegram ? capsule.addrTelegram.split(',') : []);
  const [whatsAppUsers, setWhatsAppUsers] = useState(capsule.addrWhatsApp ? capsule.addrWhatsApp.split(',') : []);
  const [facebookUsers, setFacebookUsers] = useState(capsule.addrFacebook ? capsule.addrFacebook.split(',') : []);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [whatsAppInvalid, setWhatsAppInvalid] = useState(false);

  const handleEmailAddressesAdd = (chip) => {
    if (
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
        chip
      )
    ) {
      setEmailInvalid(false);
      setEmailAddresses([...emailAddresses, chip]);
    } else {
      setEmailInvalid(true);
    }
  };

  const handleTelegramUsersAdd = (chip) => {
    setTelegramUsers([...telegramUsers, chip]);
  };

  const handleWhatsAppUsersAdd = (chip) => {
    // eslint-disable-next-line no-useless-escape
    if (
      /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(
        chip
      )
    ) {
      setWhatsAppInvalid(false);
      setWhatsAppUsers([...whatsAppUsers, chip]);
    } else {
      setWhatsAppInvalid(true);
    }
  };

  const handleFacebookUsersAdd = (chip) => {
    setFacebookUsers([...facebookUsers, chip]);
  };

  const handleDeleteAddresses = (chip) => {
    setEmailAddresses(emailAddresses.filter((item) => item !== chip));
  };

  const handleDeleteTelegramUsers = (chip) => {
    setTelegramUsers(telegramUsers.filter((item) => item !== chip));
  };

  const handleDeleteWhatsAppUsers = (chip) => {
    setWhatsAppUsers(whatsAppUsers.filter((item) => item !== chip));
  };

  const handleDeleteFacebookUsers = (chip) => {
    setFacebookUsers(facebookUsers.filter((item) => item !== chip));
  };

  useEffect(() => {
    setCapsule({
      ...capsule,
      addrEmail: emailAddresses.toString(),
      addrTelegram: telegramUsers.toString(),
      addrWhatsApp: whatsAppUsers.toString(),
      addrFacebook: facebookUsers.toString(),
      stepCover: 51,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddresses, telegramUsers, whatsAppUsers, facebookUsers]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/action/addresses' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: '24px' }}>
            {t('translation:createCapsule:capsuleAddressesStep:title')}
          </Typography>

          <Box mt={5} mb={5} display="flex">
            <EmailOutlinedIcon style={{ marginRight: 12, marginTop: 8 }} />
            <ChipInput
              placeholder={t('translation:createCapsule:capsuleAddressesStep:emailPlaceholder')}
              name="emailAddresses"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              disableUnderline
              helperText={
                emailInvalid && (
                  <span style={{ color: '#E41139' }}>
                    {t('translation:createCapsule:capsuleAddressesStep:emailInvalid')}
                  </span>
                )
              }
              newChipKeyCodes={[13, 32, 188]}
              blurBehavior={'add'}
              value={emailAddresses}
              onUpdateInput={() => setEmailInvalid(false)}
              onAdd={(chip) => {
                if (emailAddresses.length < 5) handleEmailAddressesAdd(chip);
              }}
              onDelete={(chip) => handleDeleteAddresses(chip)}
            />
          </Box>

          <Box mt={5} mb={5} display="flex">
            <TelegramIcon style={{ marginRight: 12, marginTop: 8 }} />
            <ChipInput
              placeholder={t('translation:createCapsule:capsuleAddressesStep:telegramPlaceholder')}
              value={telegramUsers}
              name="telegramUsers"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              disableUnderline
              newChipKeyCodes={[13, 32, 188]}
              blurBehavior={'add'}
              onAdd={(chip) => {
                if (telegramUsers.length < 5) handleTelegramUsersAdd(chip);
              }}
              onDelete={(chip) => handleDeleteTelegramUsers(chip)}
            />
          </Box>

          <Box mt={5} mb={5} display="flex">
            <WhatsAppIcon style={{ marginRight: 12, marginTop: 8 }} />
            <ChipInput
              placeholder={t('translation:createCapsule:capsuleAddressesStep:whatsAppPlaceholder')}
              value={whatsAppUsers}
              name="whatsAppUsers"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              disableUnderline
              newChipKeyCodes={[13, 32, 188]}
              blurBehavior={'add'}
              helperText={
                whatsAppInvalid && (
                  <span style={{ color: '#E41139' }}>
                    {t('translation:createCapsule:capsuleAddressesStep:whatsAppInvalid')}
                  </span>
                )
              }
              onUpdateInput={() => setWhatsAppInvalid(false)}
              onAdd={(chip) => {
                if (whatsAppUsers.length < 5) handleWhatsAppUsersAdd(chip);
              }}
              onDelete={(chip) => handleDeleteWhatsAppUsers(chip)}
            />
          </Box>

          <Box mt={5} mb={5} display="flex">
            <FacebookIcon style={{ marginRight: 12, marginTop: 8 }} />
            <ChipInput
              placeholder={t('translation:createCapsule:capsuleAddressesStep:facebookPlaceholder')}
              value={facebookUsers}
              name="facebookUsers"
              fullWidth
              InputProps={{
                classes: {
                  root: classes.root,
                  input: classes.input,
                },
              }}
              disableUnderline
              newChipKeyCodes={[13, 32, 188]}
              blurBehavior={'add'}
              onAdd={(chip) => {
                if (facebookUsers.length < 5) handleFacebookUsersAdd(chip);
              }}
              onDelete={(chip) => handleDeleteFacebookUsers(chip)}
            />
          </Box>

          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={props.handleNext}
              disabled={
                !emailAddresses.length && !telegramUsers.length && !whatsAppUsers.length && !facebookUsers.length
              }
            >
              {t('translation:common:next')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ActionCourierServiceForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [courierService, setCourierService] = useState(capsule.courierService || 'dhl');
  const [courierAddress, setCourierAddress] = useState(capsule.courierAddress || '');

  const handleChangeCourierService = (event) => {
    setCourierService(event.target.value);
  };

  const handleChangeCourierAddress = (event) => {
    setCourierAddress(event.target.value);
  };

  useEffect(() => {
    setCapsule({ ...capsule, courierService, courierAddress: courierAddress.trim(), stepCover: 52 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierService, courierAddress]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/action/courier' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: '24px' }}>
            {t('translation:createCapsule:capsuleDeliveryStep:title')}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup value={courierService} onChange={handleChangeCourierService}>
              <FormControlLabel
                style={{ marginBottom: 8 }}
                value="dhl"
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleDeliveryStep:option1Label')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginBottom: 8 }}
                value="fedex"
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleDeliveryStep:option2Label')}
                  </Typography>
                }
              />
              <FormControlLabel
                style={{ marginBottom: 8 }}
                value="ups"
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleDeliveryStep:option3Label')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 500, marginBottom: '24px' }}>
            {t('translation:createCapsule:capsuleDeliveryStep:deliveryAddress')}
          </Typography>

          <TextField
            required
            id="courierAddress"
            name="courierAddress"
            value={courierAddress}
            onChange={handleChangeCourierAddress}
            placeholder={t('translation:createCapsule:capsuleDeliveryStep:deliveryAddressPlaceholder')}
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
          />

          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!capsule.courierAddress || capsule.courierAddress.length < 12}
              onClick={props.handleNext}
            >
              {t('translation:common:next')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

function getActionForm(actionType, props) {
  switch (actionType) {
    case 1:
      return <ActionAddressesRecipientsForm {...props} />;
    case 5:
      return <ActionCourierServiceForm {...props} />;
    default:
      return null;
  }
}

export const CreateCapsuleStepDelivery = (props) => {
  const { capsule } = useSteps();

  return getActionForm(capsule.actionType, props);
};
