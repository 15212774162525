import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PolicyIcon from '@material-ui/icons/Policy';
import { useRouter } from '../hooks/useRouter';

import ListItemLink from './ListItemLink';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Logo from './Logo';
import CapsuleIcon from './CapsuleIcon';

const drawerWidth = 304;

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#A6AAB0',
    margin: theme.spacing(0, 1.5),
  },
  divider: {
    margin: theme.spacing(1, 1.5, 1, 1.5),
    backgroundColor: '#4E5768',
  },
  button: {
    backgroundColor: '#E41139',
    margin: theme.spacing(0, 1.5),
    height: theme.spacing(5.5),
    minWidth: theme.spacing(5.5),
    overflow: 'hidden',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '16px 24px 48px 24px',
    ...theme.mixins.toolbar,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 48,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: '#2E3542',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
}));

const LeftSidebar = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isDrawerOpened } = props;
  const router = useRouter();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !props.isDrawerOpened && classes.drawerPaperClose),
      }}
      PaperProps={{
        elevation: 2,
      }}
      open={props.isDrawerOpened}
    >
      <div className={classes.toolbarIcon}>
        <Logo type={props.isDrawerOpened ? 'inverted' : 'inverted-minimized'} />
      </div>

      <Fragment>
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box>
            <List className={classes.root}>
              <ListItemLink
                to="/dashboard"
                primary={t('translation:sidebar:menu:dashboard')}
                icon={<DashboardIcon />}
              />

              <ListItemLink to="/capsules" primary={t('translation:sidebar:menu:capsules')} icon={<CapsuleIcon />} />

              <ListItemLink to="/profile" primary={t('translation:sidebar:menu:profile')} icon={<PermIdentityIcon />} />
            </List>

            <Divider className={classes.divider} />

            <List className={classes.root}>
              <ListItemLink to="/pricing" primary={t('translation:sidebar:menu:pricing')} icon={<LocalOfferIcon />} />

              <ListItemLink to="/faq" primary="FAQ" icon={<HelpOutlineIcon />} />

              <ListItemLink to="/support" primary={t('translation:sidebar:menu:support')} icon={<HeadsetMicIcon />} />
            </List>

            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                component={Link}
                className={classes.button}
                to={{
                  pathname: "/create-capsule",
                  state: { from: router.location.pathname }
                }}
              >
                <AddIcon />
                {isDrawerOpened ? t('translation:sidebar:menu:createCapsule') : null}
              </Button>
            </div>
          </Box>

          <Box mt={6}>
            <List className={classes.root}>
              <ListItemLink
                to="/privacy-policy"
                primary={t('translation:sidebar:menu:privacy')}
                icon={<PolicyIcon />}
              />

              <ListItemLink
                to="/terms-of-service"
                primary={t('translation:sidebar:menu:terms')}
                icon={<DescriptionOutlinedIcon />}
              />
            </List>
          </Box>

          <Box mt={4}>
            <Typography
              variant="body2"
              component="p"
              style={{
                color: '#FFFFFF',
                fontSize: 12,
                margin: isDrawerOpened ? '0 28px' : '0 16px',
              }}
            >
              {isDrawerOpened ? t('translation:sidebar:version') : null} {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        </Box>
      </Fragment>
    </Drawer>
  );
};

export default LeftSidebar;
