import React from 'react';
import { Button, Box, makeStyles } from '@material-ui/core';
import { Link as RouterLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/useRouter';

const useStyles = makeStyles(() => ({
  root: {
    color: '#0064C2',
    fontWeight: 600,

    '&:hover': {
      color: '#044D90',
    },
  },
}));

const CapsuleCardComplete = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, capsuleId } = props;
  const router = useRouter();

  return (
    <Box>
      {status === 'E' ? (
        <Button
          variant="text"
          className={classes.root}
          component={RouterLink}
          to={{
            pathname: '/create-capsule/' + capsuleId,
            state: { from: router.location.pathname }
          }}
        >
          {t('translation:capsuleCardComplete:E')}
        </Button>
      ) : null}

      {status === 'N' ? (
        <Button variant="text" className={classes.root} component={Link} to={'/pay/' + capsuleId}>
          {t('translation:capsuleCardComplete:N')}
        </Button>
      ) : null}

      {status === 'P' ? (
        <Button variant="text" className={classes.root} component={RouterLink} to={'/prolong/' + capsuleId}>
          {t('translation:capsuleCardComplete:P')}
        </Button>
      ) : null}
    </Box>
  );
};

export default CapsuleCardComplete;
