import React, { Fragment, useState } from 'react';
import { Checkbox, Dialog, DialogContent, FormControlLabel, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import capsuleImgDelete from '../../../assets/capsule-delete.png';
import capsuleTrashImage from '../../../assets/capsule-trash.png';

import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/useRouter';

const DialogContentStyled = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const DeleteCapsuleDialog = (props) => {
  const [isChecked, setChecked] = useState(false);
  const [isDeleted, setDeleted] = useState(false);
  const { index, open, onClose, onCapsuleDelete } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const capsuleId = router.query.id;
  const handleChange = (event) => {
    setChecked(!isChecked);
  };

  const onSubmit = () => {
    onCapsuleDelete(index, capsuleId).then(() => {
      setDeleted(true);
    });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={onClose}
        open={open}
        onEnter={() => {
          setChecked(false);
        }}
      >
        <DialogContentStyled>
          {!isDeleted ? (
            <Fragment>
              <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <img src={capsuleImgDelete} alt={capsuleImgDelete} />
              </Box>

              <Box mt={4} textAlign="center">
                <Typography style={{ fontWeight: 500 }}>{t('translation:capsules:deleteDialogTitle')}</Typography>
                <Typography style={{ fontWeight: 400 }}>{t('translation:capsules:deleteDialogText')}</Typography>
              </Box>

              <Box mb={2} mt={2} textAlign="center">
                <FormControlLabel
                  control={<Checkbox size={'small'} checked={isChecked} onChange={handleChange} name="confirmation" />}
                  label={
                    <p style={{ fontSize: 14, textAlign: 'left' }}>{t('translation:capsules:deleteDialogCheckbox')}</p>
                  }
                />
              </Box>
            </Fragment>
          ) : null}

          {isDeleted ? (
            <Fragment>
              <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <img src={capsuleTrashImage} alt={capsuleTrashImage} />
              </Box>

              <Box mt={4} textAlign="center">
                <Typography style={{ fontWeight: 500 }}>Capsule deleted</Typography>
              </Box>
            </Fragment>
          ) : null}

          {!isDeleted ? (
            <Box mt={4} display="flex" justifyContent={'center'}>
              <Box mr={2}>
                <Button variant="outlined" size="large" onClick={onClose}>
                  {t('translation:common:cancel')}
                </Button>
              </Box>

              <Button variant="contained" disabled={!isChecked} color="primary" size="large" onClick={onSubmit}>
                {t('translation:capsules:deleteButton')}
              </Button>
            </Box>
          ) : null}

          {isDeleted ? (
            <Box mt={8} mb={4} textAlign="center">
              <Button variant="contained" color="primary" size="large" onClick={onClose}>
                {t('translation:createCapsule:goToCapsules')}
              </Button>
            </Box>
          ) : null}
        </DialogContentStyled>
      </Dialog>
    </Fragment>
  );
};

export default DeleteCapsuleDialog;
