import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';

import { convertBytesToMegaBytes, convertFromServerDate } from '../../../helpers/converter';
import DeleteCapsuleDialog from './DeleteCapsuleDialog';
import CapsuleStatusImage from './CapsuleStatusImage';
import moment from 'moment';
import CapsuleStatusPayment from './CapsuleStatusPayment';
import CapsuleStatusTitle from './CapsuleStatusTitle';
import CapsuleProtectionStatus from './CapsuleProtectionStatus';
import CapsuleCardActions from './CapsuleCardActions';
import CapsuleCardComplete from './CapsuleCardComplete';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '10px',
    padding: theme.spacing(0, 2),
    height: '100%',

    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[4],
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  date: {
    color: '#627087',
    fontSize: 16,
  },
  paymentSuccessIcon: {
    color: '#80D948',
    marginRight: theme.spacing(1),
  },
  paymentCancelIcon: {
    color: '#B00020',
    marginRight: theme.spacing(1),
  },
  statusExtra: {
    color: '#627087',
    fontSize: 12,
  },
  image: {
    maxWidth: 200,
    margin: theme.spacing(4, 8),
  },
  payment: {
    fontSize: 14,
  },
  filesLabel: {
    color: '#627087',
    fontSize: 14,
  },
  filesText: {
    color: '#2E3542',
    fontSize: 16,
  },
}));

const CapsuleCard = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, index, onCapsuleDelete } = props;
  const [isDialogOpened, setDialogOpened] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogOpened(true);
  };

  const calculateActivationDateProgress = (activationEvent, activationDate, timeCreated, storePeriod) => {
    let percent,
      creationTime = moment.unix(timeCreated),
      currentTime = moment(new Date()),
      activationTime;

    if (activationEvent === 1) {
      activationTime = moment.unix(timeCreated).add(storePeriod, 'months');
      let allPeriodMonths = activationTime.diff(creationTime, 'months');
      let nowPeriodMonths = currentTime.diff(creationTime, 'months');

      percent = (allPeriodMonths / 100) * nowPeriodMonths;
    } else if (activationEvent === 2) {
      activationTime = moment(convertFromServerDate(activationDate));
      let allPeriodMonths = activationTime.diff(creationTime, 'months');
      let nowPeriodMonths = currentTime.diff(creationTime, 'months');

      percent = (allPeriodMonths / 100) * nowPeriodMonths;
    }

    return activationEvent === 3 ? 100 : percent;
  };

  return (
    <Box style={{ height: '100%' }}>
      <DeleteCapsuleDialog
        onClose={() => {
          setDialogOpened(false);
        }}
        index={index}
        capsule={capsule}
        onCapsuleDelete={onCapsuleDelete}
        open={isDialogOpened}
      />

      <Card elevation={1} className={classes.card}>
        <CardContent>
          <Box>
            <Box mt={2} textAlign="center">
              <Typography className={classes.title}>{capsule.capsuleName}</Typography>
              <Typography className={classes.date}>{moment.unix(capsule.timeCreated).format('DD.MM.YYYY')}</Typography>
            </Box>

            <Box textAlign="center">
              <CapsuleStatusTitle status={capsule.status} />
              <CapsuleProtectionStatus status={capsule.status} />
            </Box>

            <Box mt={4} mb={4} textAlign="center">
              <CapsuleStatusImage status={capsule.status} width={230} />
            </Box>

            <Box mt={2} mb={2} textAlign="center" style={{ backgroundColor: '#F4F8FC', borderRadius: 6, padding: 8 }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box display="flex" justifyContent="center" alignItems="center">
                  {capsule.status === 'P' ? (
                    <CapsuleStatusPayment
                      status={capsule.status}
                      date={moment.unix(capsule.timeCreated).add(capsule.storePeriod, 'months').format('D MMMM YYYY')}
                    />
                  ) : capsule.status === 'N' ? (
                    <CapsuleStatusPayment status={capsule.status} date={moment.unix(capsule.timeCreated)} />
                  ) : (
                    <CapsuleStatusPayment status={capsule.status} date={moment.unix(capsule.timeCreated)} />
                  )}
                </Box>
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box textAlign="center">
                  <Typography className={classes.filesLabel}>{t('translation:common:filesClosed')}</Typography>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      backgroundColor: '#F4F8FC',
                      padding: 8,
                      borderRadius: 6,
                    }}
                  >
                    <InsertDriveFileOutlinedIcon style={{ marginRight: '8px' }} />
                    <Typography className={classes.filesText}>
                      {capsule.filesCount}
                      {' ' + t('translation:common:files')}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box textAlign="center">
                  <Typography className={classes.filesLabel}>{t('translation:common:capsuleSize')}</Typography>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      backgroundColor: '#F4F8FC',
                      padding: 8,
                      borderRadius: 6,
                    }}
                  >
                    <DataUsageOutlinedIcon style={{ marginRight: '8px' }} />
                    <Typography>{convertBytesToMegaBytes(capsule.totalFilesSize)}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {['UD', 'ND', 'PA', 'PE'].includes(capsule.status) ? null : (
              <Box mt={4}>
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ fontSize: 16 }}>{t('translation:capsuleCard:trigger')}</Typography>
                  <Typography style={{ fontSize: 16 }}>
                    {capsule.activationEvent === 1
                      ? moment.unix(capsule.timeCreated).add(capsule.storePeriod, 'months').format('DD.MM.YYYY')
                      : capsule.activationEvent === 2
                      ? moment(convertFromServerDate(capsule.activationDate)).format('DD.MM.YYYY')
                      : capsule.activationEvent === 3
                      ? t('translation:capsuleCard:byCodeword')
                      : null}
                  </Typography>
                </Box>

                <LinearProgress
                  variant="determinate"
                  value={calculateActivationDateProgress(
                    capsule.activationEvent,
                    capsule.activationDate,
                    capsule.timeCreated,
                    capsule.storePeriod
                  )}
                  style={{ height: 6, borderRadius: 5, marginTop: 3 }}
                />
              </Box>
            )}

            <Box mt={4} textAlign="center">
              <CapsuleCardActions capsule={capsule} onDelete={handleOpenDialog} />
            </Box>

            <Box mt={2} textAlign="center">
              <CapsuleCardComplete status={capsule.status} capsuleId={capsule.capsuleId} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CapsuleCard;
