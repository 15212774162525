import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { convertBytesToMegaBytes } from '../../helpers/converter';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin, faEthereum } from '@fortawesome/free-brands-svg-icons';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: 24,
    color: '#627087',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemTextSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemSecondaryAction: {
    fontSize: 24,
    color: '#627087',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  listItemSecondaryActionSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  formControlLabel: {
    marginRight: theme.spacing(6),
  },
  formControlLabelText: {
    fontSize: 24,
  },
}));

export const CreateCapsuleStepPaymentMethod = (props) => {
  const api = useApi();
  const classes = useStyles();
  const { t } = useTranslation();
  const { sealedCapsule, capsule, setCapsule } = useSteps();
  const [paymentMethod, setPaymentMethod] = useState(capsule.paymentMethod || 'BANK');

  const storagePeriodDate = moment(new Date()).add(capsule.storePeriod, 'months').format('D MMMM yyyy');
  const totalFee = sealedCapsule.capsuleFee + sealedCapsule.courierFee;
  const topUpAmount = totalFee - sealedCapsule.userBalance;

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
  };

  const getCourierServiceTitle = (courierService) => {
    switch (courierService) {
      case 'dhl':
        return 'DHL Same Day Jetline';
      case 'fedex':
        return 'FedEx International Next Flight';
      case 'ups':
        return 'UPS Express Critical';
      default:
        return 'Unknown courier service';
    }
  };

  const doTopUpBalance = () => {
    if (paymentMethod === 'BANK') {
      props.handleNext();
      setCapsule({ ...capsule, topUpAmount });
    } else {
      api
        .addFunds(paymentMethod, topUpAmount)
        .then((response) => {
          setCapsule({ ...capsule, addFunds: response.data });
          props.handleNext();
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  const doPayCapsule = () => {
    api
      .payCapsule(capsule.capsuleId)
      .then(() => {
        props.handleNext(null, 8);
        setCapsule({ ...capsule, paymentMethod, stepCover: 9 });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  useEffect(() => {
    setCapsule({ ...capsule, paymentMethod, stepCover: 7 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/summary' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Box mb={4}>
            <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
              {t('translation:createCapsule:capsulePaymentMethodStep:title')}{' '}
              <TooltipIcon
                title={t('translation:createCapsule:capsulePaymentMethodStep:titleTooltip')}
                placement="top"
              />
            </Typography>

            <List>
              <ListItem disableGutters>
                <ListItemText disableTypography className={classes.listItemText}>
                  {t('translation:createCapsule:capsulePaymentMethodStep:periodLabel')} <br />
                  {t('translation:common:until')} {storagePeriodDate}
                </ListItemText>

                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  {capsule.storePeriod} months
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />

              <ListItem disableGutters>
                <ListItemText disableTypography className={classes.listItemText}>
                  {t('translation:createCapsule:capsulePaymentMethodStep:sizeLabel')}
                </ListItemText>
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  {convertBytesToMegaBytes(sealedCapsule?.totalFilesSize)}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />

              <ListItem disableGutters>
                <ListItemText disableTypography className={classes.listItemText}>
                  {t('translation:createCapsule:capsulePaymentMethodStep:feeLabel')}
                </ListItemText>

                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  $ {sealedCapsule.capsuleFee.toFixed(2)}
                </ListItemSecondaryAction>
              </ListItem>

              {sealedCapsule.courierFee > 0 ? (
                <ListItem disableGutters>
                  <ListItemText disableTypography className={classes.listItemText}>
                    {t('translation:createCapsule:capsulePaymentMethodStep:feeFor')}{' '}
                    {getCourierServiceTitle(capsule.courierService)} {' courier delivery'}
                  </ListItemText>

                  <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    $ {sealedCapsule.courierFee.toFixed(2)}
                  </ListItemSecondaryAction>
                </ListItem>
              ) : null}

              <Divider />

              <ListItem disableGutters>
                <ListItemText disableTypography className={classes.listItemTextSummary}>
                  {t('translation:createCapsule:capsulePaymentMethodStep:total')}
                  {totalFee > sealedCapsule.userBalance ? (
                    <div
                      style={{
                        fontSize: 18,
                        color: '#B00020',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <InfoOutlinedIcon style={{ marginRight: 2 }} />
                      {t('translation:createCapsule:capsulePaymentMethodStep:notEnoughLabel', {
                        balance: sealedCapsule.userBalance.toFixed(2),
                        fee: totalFee.toFixed(2),
                      })}
                    </div>
                  ) : null}
                </ListItemText>

                <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                  $ {totalFee.toFixed(2)}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider />

              {totalFee > sealedCapsule.userBalance ? (
                <ListItem disableGutters>
                  <ListItemText disableTypography className={classes.listItemTextSummary}>
                    {t('translation:createCapsule:capsulePaymentMethodStep:topUpBalance')}
                  </ListItemText>

                  <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                    {'$ '}
                    {Math.abs(sealedCapsule.userBalance - totalFee).toFixed(2)}
                  </ListItemSecondaryAction>
                </ListItem>
              ) : (
                <ListItem disableGutters>
                  <ListItemText disableTypography className={classes.listItemTextSummary}>
                    {t('translation:createCapsule:capsulePaymentMethodStep:payFrom')}
                  </ListItemText>

                  <ListItemSecondaryAction className={classes.listItemSecondaryActionSummary}>
                    <Box textAlign="right">
                      <Box>
                        {'$ '}
                        {totalFee.toFixed(2)}
                      </Box>

                      <Typography style={{ fontSize: 16, color: '#A6AAB0', lineHeight: 0.5 }}>
                        {t('translation:createCapsule:capsulePaymentMethodStep:currentBalanceLabel')} ${' '}
                        {sealedCapsule.userBalance.toFixed(2)}
                      </Typography>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
          </Box>

          {totalFee > sealedCapsule.userBalance ? (
            <Box>
              <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
                {t('translation:createCapsule:capsulePaymentMethodStep:paymentMethodTitle')}{' '}
                <TooltipIcon
                  title={t('translation:createCapsule:capsulePaymentMethodStep:paymentMethodTooltip')}
                  placement="top"
                />
              </Typography>

              <RadioGroup name="paymentMethod" value={paymentMethod} onChange={handleChangePaymentMethod} row>
                <FormControlLabel
                  className={classes.formControlLabel}
                  value="BANK"
                  control={<Radio color="primary" />}
                  label={
                    <Box display="flex" alignItems="center">
                      <Box mr={1}>
                        <CreditCardIcon />
                      </Box>
                      <Typography className={classes.formControlLabelText} component="div">
                        <Box>Bank card</Box>
                      </Typography>
                    </Box>
                  }
                />

                <FormControlLabel
                  className={classes.formControlLabel}
                  value="BTC"
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={classes.formControlLabelText} component="div">
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <FontAwesomeIcon icon={faBitcoin} />
                        </Box>
                        <Box>Bitcoin</Box>
                      </Box>
                    </Typography>
                  }
                />

                <FormControlLabel
                  className={classes.formControlLabel}
                  value="ETH"
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={classes.formControlLabelText} component="div">
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <FontAwesomeIcon icon={faEthereum} />
                        </Box>
                        <Box>Ethereum</Box>
                      </Box>
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
          ) : null}

          <Box mt={8}>
            {sealedCapsule.userBalance - totalFee < 0 ? (
              <Button variant="contained" color="primary" size="large" onClick={doTopUpBalance}>
                {t('translation:common:topUp')}
              </Button>
            ) : (
              <Button variant="contained" color="primary" size="large" onClick={doPayCapsule}>
                {t('translation:common:pay')}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
