import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  actionText: {
    marginTop: 14,
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
  input: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));

/**
 * Capsule creation: Step 1
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateCapsuleStepName = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [capsuleName, setCapsuleName] = useState(capsule.capsuleName);
  const [years, setYears] = useState(Math.floor(capsule.storePeriod / 12) || 0);
  const [months, setMonths] = useState(capsule.storePeriod % 12 || 0);
  const [isNextDisabled, setNextDisabled] = useState(true);

  const storagePeriodDate = moment(new Date()).add(capsule.storePeriod, 'months').format('D MMMM yyyy');

  const handleChangeCapsuleName = (event) => {
    setCapsuleName(event.target.value);
  };

  const trimCapsuleName = (event) => {
    setCapsuleName(event.target.value.trim());
  };

  const handleChangeYears = (event) => {
    setYears(event.target.value);
  };

  const handleChangeMonths = (event) => {
    setMonths(event.target.value);
  };

  useEffect(() => {
    capsuleName.length && (years || months) ? setNextDisabled(false) : setNextDisabled(true);

    setCapsule({ ...capsule, capsuleName, storePeriod: years * 12 + months, stepCover: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capsuleName, years, months]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/name' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Box mb={5}>
            <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
              {t('translation:createCapsule:capsuleNameStep:nameLabel')}{' '}
              <TooltipIcon title={t('translation:createCapsule:capsuleNameStep:nameTooltip')} placement="top" />
            </Typography>

            <FormControl fullWidth>
              <TextField
                required
                inputProps={{
                  maxLength: 250,
                }}
                id="capsuleName"
                name="capsuleName"
                value={capsuleName}
                onChange={handleChangeCapsuleName}
                onBlur={trimCapsuleName}
                placeholder={t('translation:createCapsule:capsuleNameStep:namePlaceholder')}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
              {t('translation:createCapsule:capsuleNameStep:periodLabel')}{' '}
              <TooltipIcon title={t('translation:createCapsule:capsuleNameStep:periodTooltip')} placement="top" />
            </Typography>

            <FormControl>
              <TextField
                select
                value={years}
                onChange={handleChangeYears}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              >
                {[...Array(46).keys()].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item} years
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 500,
                display: 'inline-block',
                margin: '0 32px',
                lineHeight: 1.7,
              }}
            >
              and
            </Typography>

            <FormControl>
              <TextField
                select
                value={months}
                onChange={handleChangeMonths}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              >
                {[...Array(12).keys()].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item} months
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Box>

          <Box mt={2}>
            <Typography variant="body1" style={{ color: '#627087', fontSize: 16, fontWeight: 300 }}>
              {t('translation:createCapsule:capsuleNameStep:endPeriod')} {storagePeriodDate}
            </Typography>
          </Box>

          <Box mt={8}>
            <Button
              variant="contained"
              disabled={isNextDisabled}
              color="primary"
              size="large"
              onClick={props.handleNext}
            >
              {t('translation:common:next')}
            </Button>
          </Box>
          <Typography className={classes.actionText}>
            {t('translation:createCapsule:capsuleNameStep:actionDescription')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
