import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CssBaseline from '@material-ui/core/CssBaseline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';

import { CreateCapsuleStepName } from './CreateCapsuleStepName';
import { CreateCapsuleStepFiles } from './CreateCapsuleStepFiles';
import { CreateCapsuleStepExtract } from './CreateCapsuleStepExtract';
import { CreateCapsuleStepAction } from './CreateCapsuleStepAction';
import { CreateCapsuleStepDelivery } from './CreateCapsuleStepDelivery';
import { CreateCapsuleStepSettings } from './CreateCapsuleStepSettings';
import { CreateCapsuleStepPaymentMethod } from './CreateCapsuleStepPaymentMethod';
import { CreateCapsuleStepPayment } from './CreateCapsuleStepPayment';
import { useSteps } from '../../hooks/useSteps';
import { useApi } from '../../hooks/useApi';
import { useRouter } from '../../hooks/useRouter';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { DialogContent, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  step: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  stepper: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: 16,
    },
  },
  stepperConnectorLine: {
    borderColor: '#D2D9E5',
  },
  actionText: {
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
  contentWrapper: {
    padding: 32,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

const steps = new Array(8).fill('');

const getStepContent = (activeStep, handleBack, handleNext) => {
  switch (activeStep) {
    case 0:
      return <CreateCapsuleStepName activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 1:
      return <CreateCapsuleStepFiles activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 2:
      return <CreateCapsuleStepExtract activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 3:
      return <CreateCapsuleStepAction activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 4:
      return <CreateCapsuleStepDelivery activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 5:
      return <CreateCapsuleStepSettings activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 6:
      return <CreateCapsuleStepPaymentMethod activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    case 7:
      return <CreateCapsuleStepPayment activeStep={activeStep} handleBack={handleBack} handleNext={handleNext} />;
    default:
      throw new Error('Unknown step');
  }
};

const StepperStyled = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}))(Stepper);

const CreateCapsuleSteps = () => {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();
  const router = useRouter();
  const { capsule, setCapsule, INITIAL_STATE } = useSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const capsuleId = router.query.id;

  const initCapsule = () => {
    setLoading(true);
    api
      .getCapsule(capsuleId)
      .then((response) => {
        const capsuleFiles = response.data.files.map((item) => {
          return {
            file: {
              id: item.fileId,
              name: item.fileName,
              size: item.fileSize,
            },
            ready: true,
            status: 'done',
          };
        });
        setCapsule({ ...response.data, files: capsuleFiles, capsuleId: capsuleId });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext = (event, step) => {
    if (activeStep === 0 && !capsule.capsuleId) {
      api.createCapsule(capsule.capsuleName).then((response) => {
        setCapsule({
          ...capsule,
          capsuleId: response.data.capsuleId,
          emailActivationCode: response.data.emailActivationCode,
        });
      });
    }
    if (step) {
      setActiveStep(step);
    } else {
      if (activeStep === 3 && [2, 3].includes(capsule.actionType)) {
        setActiveStep(activeStep + 2);
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 5 && [2, 3].includes(capsule.actionType)) {
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleCloseCreateCapsuleLayout = () => {
    setCapsule(INITIAL_STATE);
    router.history.push(ROUTES.USER_CAPSULES);
  };

  useEffect(() => {
    if (capsule.capsuleId && capsule.status !== 'N') {
      api.updateCapsule(capsule).then(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capsule.capsuleId, activeStep]);

  useEffect(() => {
    if (capsuleId) {
      initCapsule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? null : (
    <Fragment>
      <CssBaseline />

      <Box className={classes.container}>
        {activeStep < steps.length ? (
          <StepperStyled
            activeStep={activeStep}
            className={classes.stepper}
            connector={
              <StepConnector
                classes={{
                  line: classes.stepperConnectorLine,
                }}
              />
            }
          >
            {steps.map((label, index) => (
              <Step key={index} classes={{ root: classes.step }}>
                <StepLabel>{''}</StepLabel>
              </Step>
            ))}
          </StepperStyled>
        ) : null}

        <Fragment>
          {activeStep >= steps.length ? (
            <Fragment>
              <Box textAlign="center" mt={8}>
                <Box>
                  <CheckCircleIcon style={{ fontSize: 40, color: '#80D948' }} />
                  <Typography component="h1" gutterBottom style={{ fontSize: 34, marginTop: 16 }}>
                    {t('translation:createCapsule:paymentSuccess')}
                  </Typography>
                </Box>

                <Box>
                  <Typography gutterBottom style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleHasBeenSaved')}
                  </Typography>
                </Box>

                <Box mt={6}>
                  <Button variant="outlined" size="large" onClick={handleCloseCreateCapsuleLayout}>
                    {t('translation:createCapsule:goToCapsules')}
                  </Button>
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Box className={classes.contentWrapper}>{getStepContent(activeStep, handleBack, handleNext)}</Box>
            </Fragment>
          )}
        </Fragment>
      </Box>
    </Fragment>
  );
};

export default CreateCapsuleSteps;
