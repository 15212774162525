import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useRouter } from '../hooks/useRouter';
import Logo from '../components/Logo';
import authSignUpCover from '../assets/auth-signup.png';
import authForgotCover from '../assets/auth-forgot.png';
import authPasswordCover from '../assets/auth-password.png';
import authResetCover from '../assets/auth-reset.png';
// import authVerify1 from '../assets/auth-verify-success-1.png';
import authVerify2 from '../assets/auth-verify-success-2.png';
import authVerify3 from '../assets/auth-verify-success-3.png';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  cover: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  header: {
    display: 'flex',
    padding: theme.spacing(4, 6),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 122px)',
  },
  signInCover: {
    backgroundImage: `url(${authSignUpCover})`,
  },
  signUpCover: {
    backgroundImage: `url(${authSignUpCover})`,
  },
  forgotPasswordCover: {
    backgroundImage: `url(${authForgotCover})`,
  },
  resetPasswordCover: {
    backgroundImage: `url(${authPasswordCover})`,
  },
  coverReset: {
    backgroundImage: `url(${authResetCover})`,
  },
  verifyPleaseCover: {
    backgroundImage: `url(${authVerify3})`,
  },
  verifyEmailCover: {
    backgroundImage: `url(${authVerify2})`,
  },
}));

const AuthenticationLayout = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const mode = router.query.mode;

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box className={classes.header}>
          <Link to={ROUTES.ROOT}>
            <Logo type="standard" />
          </Link>
        </Box>

        <div className={classes.container}>{props.container}</div>
      </Grid>

      <Grid item xs={false} sm={false} md={4} lg={4} className={clsx(classes.cover, classes[mode + 'Cover'])} />
    </Grid>
  );
};

export default AuthenticationLayout;
