export const VERSION = '/version';
export const GET_USER = '/getUser';
export const GET_CAPSULES = '/getCapsules';
export const GET_CAPSULES_COUNT = '/getCapsulesCount';
export const GET_CAPSULE = '/getCapsule';
export const CREATE_CAPSULE = '/createCapsule';
export const UPDATE_CAPSULE = '/updateCapsule';
export const DELETE_CAPSULE = '/deleteCapsule';
export const SEAL_CAPSULE = '/sealCapsule';
export const ADD_FILE = '/addFile';
export const UPLOAD_FILE = '/uploadFile';
export const DELETE_FILE = '/deleteFile';
export const ADD_FUNDS = '/addFunds';
export const PAY_CAPSULE = '/payCapsule';
export const GET_SERVICE_STATUS = '/getServiceStatus';
export const GET_ACTIVITY_LOG = '/getActivityLog';
export const GET_PAYMENTS = '/getPayments';
export const GET_CODEWORD_FILE = '/getCodewordFile';
export const GET_PRICES = '/getPrices';
export const REQUEST_PRICE = '/requestPrice';
export const PROLONG_CAPSULE = '/prolongCapsule';
export const REQUEST_PAYMENT = '/requestPayment';
export const SET_USER_DATA = '/setUserData';
