import React, { useEffect, useState } from 'react';
import { useSteps } from '../../hooks/useSteps';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TooltipIcon from '../../components/TooltipIcon';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  actionText: {
    color: '#A6AAB0',
    fontSize: 16,
    margin: theme.spacing(0, 3),
    display: 'inline-block',
  },
  input: {
    fontSize: 24,
  },
}));

const ProlongPeriod = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [years, setYears] = useState(0);
  const [months, setMonths] = useState(0);
  const [isNextDisabled, setNextDisabled] = useState(true);

  const actualStoragePeriod = moment.unix(capsule.timeCreated).add(capsule.storePeriod, 'months').format('D MMMM yyyy');
  const prolongedStoragePeriod = moment(actualStoragePeriod)
    .add(years * 12 + months, 'months')
    .format('D MMMM yyyy');

  const handleChangeYears = (event) => {
    setYears(event.target.value);
  };

  const handleChangeMonths = (event) => {
    setMonths(event.target.value);
  };

  useEffect(() => {
    years || months ? setNextDisabled(false) : setNextDisabled(true);

    setCapsule({ ...capsule, stepCover: 1, prolongedStoragePeriod: years * 12 + months, actualStoragePeriod });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, months]);

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/prolong/period' });
  }, []);

  return props.isLoading ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <CircularProgress color="inherit" size={40} />
    </Box>
  ) : (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Box mt={2} mb={6}>
            <Typography variant="body1" style={{ color: '#627087', fontSize: 18, fontWeight: 300 }}>
              {t('translation:prolong:currentPeriodLabel')}{' '}
              <span style={{ color: '#2E3542', fontWeight: 600 }}>{actualStoragePeriod}</span>
            </Typography>
          </Box>

          <Box>
            <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
              {t('translation:prolong:title')}{' '}
              <TooltipIcon title={t('translation:prolong:titleTooltip')} placement="top" />
            </Typography>

            <FormControl>
              <TextField
                select
                value={years}
                onChange={handleChangeYears}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              >
                {[...Array(46).keys()].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item} years
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 500,
                display: 'inline-block',
                margin: '0 32px',
                lineHeight: 1.4,
              }}
            >
              and
            </Typography>

            <FormControl>
              <TextField
                select
                value={months}
                onChange={handleChangeMonths}
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
              >
                {[...Array(12).keys()].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item} months
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Box>

          <Box mt={6}>
            <Typography variant="body1" style={{ color: '#627087', fontSize: 18, fontWeight: 300 }}>
              {t('translation:prolong:newPeriodLabel')}{' '}
              <span style={{ color: '#2E3542', fontWeight: 600 }}>{prolongedStoragePeriod}</span>
            </Typography>
          </Box>

          <Box mt={8}>
            <Button
              variant="contained"
              disabled={isNextDisabled}
              color="primary"
              size="large"
              onClick={props.handleNext}
            >
              {t('translation:common:next')}
            </Button>

            <p className={classes.actionText}>{t('translation:prolong:actionLabel')}</p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProlongPeriod;
