import React, { useEffect, useState } from 'react';

import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../hooks/useAuth';
import Typography from '@material-ui/core/Typography';
import AuthenticationTypes from '../../components/AuthenticationTypes';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useRouter } from '../../hooks/useRouter';
import * as ROUTES from '../../constants/routes';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

const defaultTheme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
  },
  input: {
    fontSize: defaultTheme.typography.fontSize + 10,
  },
}));

const INITIAL_STATE = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  error: null,
};

const SignUp = () => {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);
  const [isLoading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const handleClickShowPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);
  const handleMouseDownPasswordConfirmation = () => setShowPasswordConfirmation(!showPasswordConfirmation);

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const onRecaptchaChange = () => {
    setVerified(true);
  };

  const onSubmit = (event) => {
    const { name, email, password } = state;

    setLoading(true);
    auth
      .signUp(email.trim(), password.trim())
      .then((user) => {
        setState(INITIAL_STATE);

        user
          .updateProfile({
            displayName: name.trim(),
          })
          .then(() => {});

        user.sendEmailVerification().then(() => {
          setLoading(false);
        });

        router.history.push(ROUTES.AUTH_VERIFY_PLEASE);
      })
      .catch((error) => {
        setLoading(false);
        setState({ ...state, error });
      });
    event.preventDefault();
  };

  const { name, email, password, passwordConfirmation, error } = state;
  const isInvalid = password !== passwordConfirmation || password === '' || email === '' || name === '';

  useEffect(() => {
    document.title = 'Sign up - SaveToFuture';
  }, []);

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4">
        {t('translation:auth:signUp:title')}
      </Typography>

      <AuthenticationTypes />

      <Box textAlign="center" mb={4}>
        {error && (
          <Typography component="p" variant="body1" className={classes.error}>
            {error.message}
          </Typography>
        )}

        {password.length > 0 && passwordConfirmation.length > 0 && password !== passwordConfirmation && (
          <Typography component="p" variant="body1" className={classes.error}>
            {t('translation:auth:signUp:passwordsError')}
          </Typography>
        )}
      </Box>

      <Container maxWidth="sm">
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={onChange}
            placeholder={t('translation:auth:signUp:inputNamePlaceholder')}
            required
            autoFocus
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />

          <TextField
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder={t('translation:auth:signUp:inputEmailPlaceholder')}
            required
            fullWidth
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
          />

          <TextField
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            onChange={onChange}
            placeholder={t('translation:auth:signUp:inputPasswordPlaceholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            required
            fullWidth
            style={{ marginBottom: 24 }}
          />

          <TextField
            type={showPasswordConfirmation ? 'text' : 'password'}
            id="passwordConfirmation"
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={onChange}
            placeholder={t('translation:auth:signUp:inputConfirmPlaceholder')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirmation}
                    onMouseDown={handleMouseDownPasswordConfirmation}
                  >
                    {showPasswordConfirmation ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.input,
              },
            }}
            style={{ marginBottom: 24 }}
            required
            fullWidth
          />

          <Box display="flex" justifyContent="center">
            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KAY} onChange={onRecaptchaChange} />
          </Box>

          <Box display="flex" flexDirection="column">
            <Box textAlign="center" mt={3} mb={5}>
              <Button type="submit" color="primary" size="large" variant="contained" disabled={isInvalid || !verified}>
                {!isLoading ? (
                  t('translation:auth:signUp:signUpButton')
                ) : (
                  <CircularProgress color="inherit" size={26} />
                )}
              </Button>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography component="div">
                <Box>{t('translation:auth:signUp:haveAccount')}</Box>
              </Typography>

              <Box ml={1}>
                <Button component={Link} to={ROUTES.AUTH_SIGN_IN} color="secondary" style={{ fontWeight: 600 }}>
                  {t('translation:auth:signUp:signInLabel')}
                </Button>
              </Box>
            </Box>

            <Box textAlign="center" mt={2}>
              <Typography component="p" variant="body2">
                {t('translation:auth:signUp:licenseLabel')}
              </Typography>
            </Box>
          </Box>
        </form>
      </Container>
    </div>
  );
};

export default SignUp;
