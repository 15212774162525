import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Typography,
  Container,
  makeStyles,
  Box,
  Button,
  ListItem,
  ListItemText,
  List,
  Divider,
  CircularProgress,
  ListItemIcon,
  Chip,
  IconButton,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';

import DHLLogoImage from '../../assets/dhl-logo.png';
import FedExLogoImage from '../../assets/fedex-logo.png';
import UPSLogoImage from '../../assets/ups-logo.png';
import { useRouter } from '../../hooks/useRouter';
import { useApi } from '../../hooks/useApi';
import { convertBytesToMegaBytes, convertFromServerDate } from '../../helpers/converter';
import CapsuleStatusImage from './components/CapsuleStatusImage';
import CapsuleStatusPayment from './components/CapsuleStatusPayment';
import TooltipIcon from '../../components/TooltipIcon';
import DestroyIcon from '../../components/DestroyIcon';
import DeleteCapsuleDialog from './components/DeleteCapsuleDialog';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  customHoverFocus: {
    backgroundColor: '#F4F8FC',
    color: '#2E3542',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#F4F8FC' },
  },
}));

const courierServiceLogo = {
  dhl: DHLLogoImage,
  fedex: FedExLogoImage,
  ups: UPSLogoImage,
};

const CapsuleView = () => {
  const classes = useStyles();
  const router = useRouter();
  const api = useApi();
  const { t } = useTranslation();
  const [capsule, setCapsule] = useState();
  const [isLoading, setLoading] = useState(true);
  const capsuleId = router.query.id;
  const [isDialogOpened, setDialogOpened] = React.useState(false);

  const handleOpenDialog = () => {
    setDialogOpened(true);
  };

  const onCapsuleDelete = (index, capsuleId) => {
    return new Promise((resolve, reject) => {
      api
        .deleteCapsule(capsuleId)
        .then(() => {
          router.history.push(ROUTES.USER_CAPSULES);

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    setLoading(true);

    api
      .getCapsule(capsuleId)
      .then((response) => {
        setCapsule(response.data);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={classes.container} maxWidth="lg">
      <DeleteCapsuleDialog
        onClose={() => {
          setDialogOpened(false);
        }}
        index={null}
        capsule={capsule}
        onCapsuleDelete={onCapsuleDelete}
        open={isDialogOpened}
      />

      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Box>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link component={RouterLink} to={'/capsules'} color="inherit" style={{ fontSize: 20 }}>
                {t('translation:capsules:title')}
              </Link>

              <Typography color="textPrimary" component="div">
                <Box fontSize={20} fontWeight={600}>
                  {t('translation:capsules:view:title')}
                </Box>
              </Typography>
            </Breadcrumbs>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {!isLoading && !['UD', 'ND', 'PA', 'PE'].includes(capsule.status) ? (
            <Box display="flex">
              {capsule.allowDelete === 1 ? (
                <Box mr={2}>
                  <Button startIcon={<DeleteOutlinedIcon />} onClick={handleOpenDialog}>
                    {t('translation:capsules:view:deleteButton')}
                  </Button>
                </Box>
              ) : null}

              {capsule.status !== 'E' && capsule.allowChange === 1 ? (
                <Box>
                  <Button
                    startIcon={<EditOutlinedIcon />}
                    component={RouterLink}
                    to={'/capsules/' + capsuleId + '/edit'}
                  >
                    {t('translation:capsules:view:editButton')}
                  </Button>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Grid>
      </Grid>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <Box p={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box display="flex" justifyContent="center">
                      <CapsuleStatusImage status={capsule.status} width={230} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8}>
                    <Box>
                      <List>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography component="span">
                                <Box fontSize={16} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                  {capsule.capsuleName}
                                </Box>
                              </Typography>
                            }
                            secondary={
                              <Typography component="span" style={{ color: '#A6AAB0', lineHeight: 0.7 }}>
                                <Box fontSize={14}>{t('translation:capsules:view:nameLabel')}</Box>
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />

                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography component="span">
                                <Box fontSize={16}>{moment.unix(capsule.timeCreated).format('DD.MM.YYYY')}</Box>
                              </Typography>
                            }
                            secondary={
                              <Typography component="span" style={{ color: '#A6AAB0', lineHeight: 0.7 }}>
                                <Box fontSize={14}>{t('translation:capsules:view:dateLabel')}</Box>
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />

                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography component="span">
                                <Box fontSize={16}>{capsule.storePeriod + ' months'}</Box>
                              </Typography>
                            }
                            secondary={
                              <Typography component="span" style={{ color: '#A6AAB0', lineHeight: 0.7 }}>
                                <Box fontSize={14}>{t('translation:capsules:view:periodLabel')}</Box>
                              </Typography>
                            }
                          />
                        </ListItem>
                        <Divider />

                        {!['UD', 'ND'].includes(capsule.status) ? null : (
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography component="span">
                                  <Box fontSize={16}>{moment.unix(capsule.timeUpdated).format('DD.MM.YYYY')}</Box>
                                </Typography>
                              }
                              secondary={
                                <Typography component="span" style={{ color: '#A6AAB0', lineHeight: 0.7 }}>
                                  <Box fontSize={14}>{'Deletion time'}</Box>
                                </Typography>
                              }
                            />
                          </ListItem>
                        )}

                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              capsule.status === 'E' ? (
                                <Grid container justify="space-between">
                                  <Grid item xs={12} sm={6} md={6}>
                                    <CapsuleStatusPayment
                                      status={capsule.status}
                                      date={moment.unix(capsule.timeCreated)}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                      style={{ color: '#0064C2', fontWeight: 600 }}
                                      component={RouterLink}
                                      to={{
                                        pathname: '/create-capsule/' + capsuleId,
                                        state: { from: router.location.pathname },
                                      }}
                                    >
                                      {t('translation:capsuleCardComplete:E')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : capsule.status === 'P' ? (
                                <Grid container justify="space-between" alignItems="center">
                                  <Grid item xs={12} sm={6} md={6}>
                                    <Box display="flex">
                                      <CapsuleStatusPayment
                                        status={capsule.status}
                                        date={moment
                                          .unix(capsule.timeCreated)
                                          .add(capsule.storePeriod, 'months')
                                          .format('D MMMM YYYY')}
                                      />
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Box display="flex">
                                      <Button
                                        style={{ color: '#0064C2', fontWeight: 600 }}
                                        component={RouterLink}
                                        to={'/prolong/' + capsuleId}
                                      >
                                        {t('translation:capsuleCardComplete:P')}
                                      </Button>
                                    </Box>
                                  </Grid>
                                </Grid>
                              ) : capsule.status === 'N' ? (
                                <Grid container justify="space-between">
                                  <Grid item xs={12} sm={6} md={6}>
                                    <Box display="flex">
                                      <CapsuleStatusPayment
                                        status={capsule.status}
                                        date={moment.unix(capsule.timeCreated)}
                                      />
                                    </Box>
                                  </Grid>

                                  <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                      style={{ color: '#0064C2', fontWeight: 600 }}
                                      component={RouterLink}
                                      to={'/pay/' + capsuleId}
                                    >
                                      {t('translation:capsuleCardComplete:N')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              ) : null
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>

                    <Box>
                      <List dense={true}>
                        {capsule.allowChange === 0 ? (
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center">
                                  <EditOutlinedIcon style={{ color: '#B8B8B8' }} />
                                  <Typography component="div">
                                    <Box fontSize={16} ml={1}>
                                      {t('translation:capsuleSettings:edit')}
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        ) : null}

                        {capsule.allowDelete === 0 ? (
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center">
                                  <DeleteForeverOutlinedIcon style={{ color: '#B8B8B8' }} />
                                  <Typography component="div">
                                    <Box fontSize={16} ml={1}>
                                      {t('translation:capsuleSettings:delete')}
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        ) : null}

                        {capsule.hideNames === 1 ? (
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center">
                                  <VisibilityOffOutlinedIcon style={{ color: '#B8B8B8' }} />
                                  <Typography component="div">
                                    <Box fontSize={16} ml={1}>
                                      {t('translation:capsuleSettings:hidden')}
                                    </Box>
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={7}>
            {['UD', 'ND'].includes(capsule.status) ? null : (
              <Paper elevation={2}>
                <Box p={4}>
                  <Box pb={3}>
                    <Typography component="div">
                      <Box mb={2} fontWeight={600}>
                        {t('translation:capsules:view:extract:title')}
                      </Box>
                    </Typography>

                    <Typography component="div">
                      {capsule.activationDate && capsule.activationEvent === 1 ? (
                        <Box fontSize={16}>
                          {t('translation:capsules:view:extract:endLabel')} —{' '}
                          {moment.unix(capsule.timeCreated).add(capsule.storePeriod, 'months').format('DD.MM.YYYY')}
                        </Box>
                      ) : null}

                      {capsule.activationDate && capsule.activationEvent === 2 ? (
                        <Box fontSize={16}>
                          {t('translation:common:on')}{' '}
                          {moment(convertFromServerDate(capsule.activationDate)).format('DD.MM.YYYY')}
                        </Box>
                      ) : null}

                      {capsule.activationEvent === 3 ? (
                        <Box fontSize={16}>
                          {t('translation:capsules:view:extract:codewordLabel')}{' '}
                          <TooltipIcon
                            title={t('translation:capsules:view:extract:codewordLabelTooltip')}
                            placement="top"
                          />
                        </Box>
                      ) : null}
                    </Typography>
                  </Box>

                  <Divider />

                  <Box pt={3}>
                    <Typography component="div">
                      <Box mb={2} fontWeight={600}>
                        {t('translation:capsules:view:destination:title')}
                      </Box>
                    </Typography>

                    {capsule.actionType === 1 ? (
                      <List dense={true}>
                        {capsule.addrEmail ? (
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <IconButton className={classes.customHoverFocus}>
                                <EmailOutlinedIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText
                              style={{ overflow: 'hidden' }}
                              primary={capsule.addrEmail.split(',').map((item, index) => {
                                return <Chip key={index} label={item} style={{ backgroundColor: '#F4F8FC' }} />;
                              })}
                            />
                          </ListItem>
                        ) : null}

                        {capsule.addrTelegram ? (
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <IconButton className={classes.customHoverFocus}>
                                <TelegramIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText
                              style={{ overflow: 'hidden' }}
                              primary={capsule.addrTelegram.split(',').map((item, index) => {
                                return <Chip key={index} label={item} style={{ backgroundColor: '#F4F8FC' }} />;
                              })}
                            />
                          </ListItem>
                        ) : null}

                        {capsule.addrWhatsApp ? (
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <IconButton className={classes.customHoverFocus}>
                                <WhatsAppIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText
                              style={{ overflow: 'hidden' }}
                              primary={capsule.addrWhatsApp.split(',').map((item, index) => {
                                return <Chip key={index} label={item} style={{ backgroundColor: '#F4F8FC' }} />;
                              })}
                            />
                          </ListItem>
                        ) : null}

                        {capsule.addrFacebook ? (
                          <ListItem disableGutters>
                            <ListItemIcon>
                              <IconButton className={classes.customHoverFocus}>
                                <FacebookIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText
                              style={{ overflow: 'hidden' }}
                              primary={capsule.addrFacebook.split(',').map((item, index) => {
                                return <Chip key={index} label={item} style={{ backgroundColor: '#F4F8FC' }} />;
                              })}
                            />
                          </ListItem>
                        ) : null}
                      </List>
                    ) : null}

                    {capsule.actionType === 2 ? (
                      <Box display="flex" alignItems="center">
                        <PublicOutlinedIcon />
                        <Typography component="div">
                          <Box fontSize={16} ml={2}>
                            {t('translation:capsules:view:destination:publicLabel')}
                          </Box>
                        </Typography>
                      </Box>
                    ) : null}

                    {capsule.actionType === 3 ? (
                      <Box display="flex" alignItems="center">
                        <DestroyIcon />
                        <Typography component="div">
                          <Box fontSize={16} ml={2}>
                            {t('translation:capsules:view:destination:destroyLabel')}
                          </Box>
                        </Typography>
                      </Box>
                    ) : null}

                    {capsule.actionType === 5 ? (
                      <Box mt={2}>
                        <Box display="flex" mb={2} alignItems="center">
                          <Box mr={2}>
                            <img
                              src={courierServiceLogo[capsule.courierService]}
                              alt={courierServiceLogo[capsule.courierService]}
                            />
                          </Box>

                          <Typography component="div">
                            <Box fontSize={14}>Deliver by express courier service</Box>
                          </Typography>
                        </Box>

                        <Typography component="div">
                          <Box fontSize={16}>{capsule.courierAddress}</Box>
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Paper>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            {['UD', 'ND'].includes(capsule.status) ? null : (
              <Paper elevation={2}>
                <Box p={4}>
                  <Typography component="div">
                    <Box mb={4} fontWeight={600}>
                      {['PA', 'PE'].includes(capsule.status)
                        ? t('translation:capsules:view:data:titleOpened')
                        : t('translation:capsules:view:data:title')}
                    </Box>
                  </Typography>

                  {['PA', 'PE'].includes(capsule.status) ? null : (
                    <Box display="flex" justifyContent="flex-start">
                      <Box mr={4}>
                        <Box display="flex" alignItems="center">
                          <DataUsageOutlinedIcon style={{ marginRight: '8px' }} />

                          <Box>
                            <Typography component="div">
                              <Box fontSize={16} fontWeight={500}>
                                {capsule.files.length
                                  ? convertBytesToMegaBytes(
                                      capsule.files
                                        .map((item) => item.fileSize)
                                        .reduce((previous, current) => previous + current)
                                    )
                                  : '0 Mb'}
                              </Box>
                            </Typography>
                            <Typography component="div" style={{ color: '#627087', lineHeight: 1 }}>
                              <Box fontSize={14}>{t('translation:capsules:view:data:sizeLabel')}</Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Box>
                        <Box display="flex" alignItems="center">
                          <InsertDriveFileOutlinedIcon style={{ marginRight: '8px' }} />

                          <Box>
                            <Typography component="div">
                              <Box fontSize={16} fontWeight={500}>
                                {capsule.files.length + ' files'}
                              </Box>
                            </Typography>

                            <Typography component="div" style={{ color: '#627087', lineHeight: 1 }}>
                              <Box fontSize={14}>{t('translation:capsules:view:data:filesLabel')}</Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {['PA', 'PE'].includes(capsule.status) ? null : (
                    <Box mt={4}>
                      {capsule.files.length ? (
                        <List dense={true}>
                          {capsule.files.map((file, index) => {
                            return (
                              <ListItem key={index} disableGutters>
                                <ListItemIcon>
                                  <IconButton className={classes.customHoverFocus}>
                                    <InsertDriveFileOutlinedIcon />
                                  </IconButton>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography component="span">
                                      <Box fontSize={16}>{file.fileName}</Box>
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography component="span" style={{ color: '#627087' }}>
                                      <Box fontSize={14}>{convertBytesToMegaBytes(file.fileSize)}</Box>
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : null}
                    </Box>
                  )}
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default CapsuleView;
