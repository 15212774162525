import React, { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin, faEthereum } from '@fortawesome/free-brands-svg-icons';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    fontSize: 24,
    color: '#627087',
  },
  listItemTextSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
  },
  listItemSecondaryAction: {
    fontSize: 24,
    color: '#627087',
  },
  listItemSecondaryActionSummary: {
    fontSize: 24,
    fontWeight: 500,
    color: '#2E3542',
  },
  formControlLabel: {
    marginRight: theme.spacing(6),
  },
  formControlLabelText: {
    fontSize: 24,
  },
  input: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'right',
  },
}));

const TopUpBalance = (props) => {
  const api = useApi();
  const auth = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(capsule?.paymentMethod || 'BANK');
  const [topUpAmount, setTopUpAmount] = useState(capsule?.topUpAmount || 1);

  const getData = async () => {
    const response = await api.getUser();
    setUserInfo(response.data);
  };

  const handleChangeTopUpAmount = (event) => {
    const topUpAmount = Math.round((+event.target.value + Number.EPSILON) * 100) / 100;
    setTopUpAmount(topUpAmount);
    setCapsule({ ...capsule, topUpAmount });
  };

  const handleChangePaymentMethod = (event) => {
    setPaymentMethod(event.target.value);
    setCapsule({ ...capsule, paymentMethod: event.target.value });
  };

  const doTopUpBalance = () => {
    if (paymentMethod === 'BANK') {
      props.handleNext();
      // setCapsule({ ...capsule, topUpAmount });
    } else {
      api
        .addFunds(paymentMethod, Number(topUpAmount))
        .then((response) => {
          setCapsule({ ...capsule, addFunds: response.data });
          props.handleNext();
        })
        .catch((error) => {
          throw new Error(error);
        });
    }
  };

  useEffect(() => {
    setCapsule({ ...capsule, stepCover: 7 });

    if (auth.isReady) {
      getData().finally(() => {
        setLoading(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    setCapsule({ ...capsule, paymentMethod, stepCover: 7 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/top-up/balance' });
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={12}>
              <Box mb={8} display="flex" alignItems="center">
                <AccountBalanceWalletIcon />

                <Typography component="span">
                  <Box mr={1} ml={2}>
                    {t('translation:topUpBalance:balanceLabel')}
                  </Box>
                </Typography>

                <Typography component="span">
                  <Box fontSize={24} fontWeight={600} mr={1}>
                    {userInfo.balance.toFixed(2)} US$
                  </Box>
                </Typography>

                <TooltipIcon title={t('translation:topUpBalance:balanceLabelTooltip')} placement="top" />
              </Box>

              <Box mb={8}>
                <Box mb={3} display="flex" alignItems="center">
                  <Typography component="div">
                    <Box fontSize={24} fontWeight={600} mr={1}>
                      {t('translation:topUpBalance:title1')}
                    </Box>
                  </Typography>
                  <TooltipIcon title={t('translation:topUpBalance:title1Tooltip')} placement="top" />
                </Box>

                <Box display="flex" alignItems="center">
                  <Typography component="div">
                    <Box mr={1}>{t('translation:topUpBalance:topUpForLabel')}</Box>
                  </Typography>

                  <Box maxWidth={120} mr={1} ml={1}>
                    <TextField
                      placeholder="100"
                      type="number"
                      value={topUpAmount}
                      onChange={handleChangeTopUpAmount}
                      InputProps={{
                        classes: {
                          input: classes.input,
                        },
                        inputProps: { min: 1, step: 0.05 },
                      }}
                    />
                  </Box>

                  <Typography component="div">
                    <Box fontSize={24} fontWeight={600}>
                      US$
                    </Box>
                  </Typography>
                </Box>
              </Box>

              <Box mb={8}>
                <Box mb={3} display="flex" alignItems="center">
                  <Typography component="div">
                    <Box fontSize={24} fontWeight={600} mr={1}>
                      {t('translation:topUpBalance:title2')}
                    </Box>
                  </Typography>
                  <TooltipIcon title={t('translation:topUpBalance:title2Tooltip')} placement="top" />
                </Box>

                <Box>
                  <RadioGroup name="paymentMethod" value={paymentMethod} onChange={handleChangePaymentMethod} row>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="BANK"
                      control={<Radio color="primary" />}
                      label={
                        <Box display="flex" alignItems="center">
                          <Box mr={1}>
                            <CreditCardIcon />
                          </Box>
                          <Typography className={classes.formControlLabelText} component="div">
                            <Box>{t('translation:topUpBalance:radio1')}</Box>
                          </Typography>
                        </Box>
                      }
                    />

                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="BTC"
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.formControlLabelText} component="div">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <FontAwesomeIcon icon={faBitcoin} />
                            </Box>
                            <Box>{t('translation:topUpBalance:radio2')}</Box>
                          </Box>
                        </Typography>
                      }
                    />

                    <FormControlLabel
                      className={classes.formControlLabel}
                      value="ETH"
                      control={<Radio color="primary" />}
                      label={
                        <Typography className={classes.formControlLabelText} component="div">
                          <Box display="flex" alignItems="center">
                            <Box mr={1}>
                              <FontAwesomeIcon icon={faEthereum} />
                            </Box>
                            <Box>{t('translation:topUpBalance:radio3')}</Box>
                          </Box>
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={doTopUpBalance}
                  disabled={topUpAmount < 1}
                >
                  {t('translation:common:topUp')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default TopUpBalance;
