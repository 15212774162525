import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Typography, TextField, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProfileChangeEmailDialog = (props) => {
  const auth = useAuth();
  const { email, open, onClose } = props;
  const { handleSubmit, control } = useForm();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(false);

    auth.user.reauthenticateWithCredential(auth.user);

    // auth.user
    //   .updateEmail(data.email)
    //   .then(function () {
    //     onClose();
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <DialogContent>
        <Box textAlign="center">
          <Typography component="div">
            <Box textAlign="center" fontSize={16} fontWeight={500}>
              Change email
            </Box>
          </Typography>

          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Box textAlign="center">
              <Box mt={4} mb={4}>
                <Controller
                  as={<TextField size="small" />}
                  name="email"
                  control={control}
                  defaultValue={email}
                  label="Change email"
                />
              </Box>

              <Box mt={4} mb={4} display="flex" justifyContent="center">
                <Box mr={2}>
                  <Button variant="outlined" size="large" onClick={onClose}>
                    {'Cancel'}
                  </Button>
                </Box>

                <Box>
                  <Button variant="contained" color="primary" size="large" type="submit">
                    {!isLoading ? 'Save' : <CircularProgress color="inherit" size={26} />}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileChangeEmailDialog;
