/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Slider,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import capsuleOpened from '../assets/capsule-opened.png';
import price25 from '../assets/price-25.png';
import price100 from '../assets/price-100.png';
import price1000 from '../assets/price-1000.png';
import DHLLogoImage from '../assets/dhl-logo.png';
import FedExLogoImage from '../assets/fedex-logo.png';
import UPSLogoImage from '../assets/ups-logo.png';
import clsx from 'clsx';
import { useApi } from '../hooks/useApi';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  paper: {
    padding: theme.spacing(3),
  },
  discountBar: {
    backgroundColor: '#515C70',
    width: '100%',
    height: 30,
    borderRadius: 3,
    color: '#FFFFFF',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ten: {
    backgroundColor: '#B7BFCF',
    width: '22.22%',
  },
  twenty: {
    backgroundColor: '#8692A9',
    width: '44.44%',
  },
  thirty: {
    backgroundColor: '#627087',
    width: '66.66%',
  },
  discountBox: {
    backgroundColor: '#F1FEE9',
    fontSize: 24,
    borderRadius: 4,
  },
  pricesLess: {
    fontSize: 24,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  totalPriceBox: {
    color: '#0064C2',
    fontSize: 24,
    fontWeight: 600,
    border: '2px solid #0064C2',
    borderRadius: 4,
  },
  calculatorGrid: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  calculatorRadioGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
}));

const StorageDurationSlider = withStyles({
  track: {
    height: 3,
  },
})(Slider);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#2E3542',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    zIndex: 1,
  },
}))(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <LightTooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </LightTooltip>
  );
}

const Pricing = () => {
  const classes = useStyles();
  const api = useApi();
  const { t } = useTranslation();
  const [prices, setPrices] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [size, setSize] = useState('0');
  const [duration, setDuration] = useState(12);
  const [courier, setCourier] = useState(false);

  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const getPrices = () => {
    setLoading(true);
    api
      .getPrices()
      .then((response) => {
        setPrices(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
  };

  const handleDurationChange = (e, value) => {
    setDuration(value);
  };

  const handleCourierChange = (event) => {
    setCourier(event.target.checked);
  };

  const calculate = () => {
    let totalValue;
    let durationInMonths = duration;

    const price = prices.prices.filter((item) => {
      return item[0] === Number(size);
    });

    const discount = prices.discounts.filter((item) => {
      return durationInMonths >= item[0] && durationInMonths <= item[1];
    });

    totalValue = price[0][2] * durationInMonths;
    totalValue = totalValue - (totalValue * discount[0][2]) / 100;
    totalValue += courier ? prices.courierPrice : 0;

    setDiscount(discount[0][2]);
    setTotalPrice(totalValue);
  };

  useEffect(() => {
    getPrices();
  }, []);

  useEffect(() => {
    if (prices) {
      calculate();
    }
  }, [prices, courier, size, duration]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography component="div">
        <Box fontSize={20} lineHeight={1.1} fontWeight={600}>
          {t('translation:pricing:title')}
        </Box>
      </Typography>

      <Typography component="div">
        <Box mb={2} fontSize={16}>
          {t('translation:pricing:subtitle')}
        </Box>
      </Typography>

      <Divider light />

      {isLoading ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        <Box>
          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={600}>
              {t('translation:pricing:prices:title')}
            </Box>
          </Typography>

          <Grid container spacing={3}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Paper elevation={1} style={{ height: '100%', display: 'flex' }}>
                  <Box p={3} pb={2} display="flex" alignItems="center">
                    <Box mr={4}>
                      <img src={price25} alt={price25} />
                    </Box>

                    <Box>
                      <Typography component="div">
                        <Box fontSize={18} fontWeight={600}>
                          {t('translation:pricing:prices:less')} {prices.prices[0][1]} Mb
                        </Box>
                      </Typography>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography component="span">
                          <Box className={classes.pricesLess}>
                            ${prices.prices[0][2]}
                          </Box>
                        </Typography>

                        <Typography component="span">
                          <Box fontSize={18}>/ Month</Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Paper elevation={1} style={{ height: '100%', display: 'flex' }}>
                  <Box p={3} pb={2} display="flex" alignItems="center">
                    <Box mr={4}>
                      <img src={price100} alt={price100} />
                    </Box>
                    <Box>
                      <Typography component="div">
                        <Box fontSize={18} fontWeight={600}>
                          {t('translation:pricing:prices:from')} {prices.prices[1][0]}{' '}
                          {t('translation:pricing:prices:to')} {prices.prices[1][1]} Mb
                        </Box>
                      </Typography>

                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography component="span">
                          <Box className={classes.pricesLess}>
                            ${prices.prices[1][2]}
                          </Box>
                        </Typography>

                        <Typography component="span">
                          <Box fontSize={18}>/ Month</Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Paper elevation={1}>
                  <Box p={3} pb={2} display="flex" alignItems="center">
                    <Box mr={4}>
                      <img src={price1000} alt={price1000} />
                    </Box>
                    <Box>
                      <Typography component="div">
                        <Box fontSize={18} fontWeight={600}>
                          {t('translation:pricing:prices:from')} {prices.prices[2][0]}{' '}
                          {t('translation:pricing:prices:to')} {prices.prices[2][1]} Mb
                        </Box>
                      </Typography>

                      <Box display="flex" alignItems="center" mb={1}>
                        <Typography component="span">
                          <Box className={classes.pricesLess}>
                            ${prices.prices[2][2]}
                          </Box>
                        </Typography>

                        <Typography component="span">
                          <Box fontSize={18}>/ Month</Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={600}>
              {t('translation:pricing:reduction:title')}
            </Box>
          </Typography>

          <Box>
            <Grid container spacing={2} direction="row" alignItems="stretch">
              <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={1} style={{ height: '100%' }}>
                  <Box p={4}>
                    <Typography component="div">
                      <Box mb={4} fontSize={16} fontWeight={600}>
                        {t('translation:pricing:reduction:subtitle')}
                      </Box>
                    </Typography>

                    <Box>
                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={3}>
                          {t('translation:pricing:reduction:from')} 1 {t('translation:pricing:reduction:to')} 2 years
                        </Grid>
                        <Grid item xs={9}>
                          <Box className={clsx(classes.discountBar, classes.ten)}>10%</Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={3}>
                          {t('translation:pricing:reduction:from')} 2 {t('translation:pricing:reduction:to')} 5 years
                        </Grid>
                        <Grid item xs={9}>
                          <Box className={clsx(classes.discountBar, classes.twenty)}>20%</Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={3}>
                          {t('translation:pricing:reduction:from')} 5 {t('translation:pricing:reduction:to')} 15 years
                        </Grid>
                        <Grid item xs={9}>
                          <Box className={clsx(classes.discountBar, classes.thirty)}>30%</Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box>
                      <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item xs={3}>
                          {t('translation:pricing:reduction:more')} 15 years
                        </Grid>
                        <Grid item xs={9}>
                          <Box className={clsx(classes.discountBar)}>45%</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Paper elevation={1} style={{ height: '100%' }}>
                  <Box p={4}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box mr={4}>
                        <img src={DHLLogoImage} alt={DHLLogoImage} style={{ width: '100%' }} />
                      </Box>
                      <Box mr={4}>
                        <img src={FedExLogoImage} alt={FedExLogoImage} style={{ width: '100%' }} />
                      </Box>
                      <Box>
                        <img src={UPSLogoImage} alt={UPSLogoImage} style={{ width: '100%' }} />
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="flex-start" mb={1}>
                      <Typography component="span">
                        <Box mr={1} fontSize={24} fontWeight={600}>
                          ${prices.courierPrice}
                        </Box>
                      </Typography>

                      <Typography component="span">
                        <Box fontSize={18}>/ {t('translation:pricing:delivery:priceDescription')}</Box>
                      </Typography>
                    </Box>

                    <Box>
                      <Typography component="div">
                        <Box fontSize={18} fontWeight={600}>
                          {t('translation:pricing:delivery:services')}
                        </Box>
                      </Typography>

                      <Typography component="div">
                        <Box fontSize={14} lineHeight={1} color="#A6AAB0">
                          {t('translation:pricing:delivery:servicesDescription')}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={600}>
              {t('translation:pricing:calculator:title')}
            </Box>
          </Typography>

          <Box>
            <Paper elevation={1}>
              <Box p={2} pr={4}>
                <Grid container spacing={2} alignItems="stretch" className={classes.calculatorGrid}>
                  <Grid item xs={12} sm={12} md={4}>
                    <Box p={2}>
                      <Typography component="div">
                        <Box mb={4} fontSize={18} fontWeight={600}>
                          {t('translation:pricing:calculator:radioLabel')}
                        </Box>
                      </Typography>

                      <Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            defaultValue={size}
                            value={size}
                            onChange={handleChangeSize}
                            className={classes.calculatorRadioGroup}
                          >
                            <FormControlLabel
                              value={'0'}
                              control={<Radio color="primary" />}
                              label={t('translation:pricing:calculator:less') + ' 25 Mb'}
                            />
                            <FormControlLabel
                              value={'25'}
                              control={<Radio color="primary" />}
                              label={
                                t('translation:pricing:calculator:from') +
                                ' 25 ' +
                                t('translation:pricing:calculator:to') +
                                ' 100 Mb'
                              }
                            />
                            <FormControlLabel
                              value={'100'}
                              control={<Radio color="primary" />}
                              label={
                                t('translation:pricing:calculator:from') +
                                ' 100 ' +
                                t('translation:pricing:calculator:to') +
                                ' 1024 Mb'
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8}>
                    <Box p={2}>
                      <Typography component="div">
                        <Box mb={2} fontSize={18} fontWeight={600}>
                          {t('translation:pricing:calculator:barLabel')}
                        </Box>
                      </Typography>

                      <Box>
                        <Box mb={4}>
                          <StorageDurationSlider
                            ValueLabelComponent={ValueLabelComponent}
                            defaultValue={12}
                            valueLabelDisplay="on"
                            step={1}
                            max={240}
                            min={1}
                            onChangeCommitted={handleDurationChange}
                            valueLabelFormat={(x) => {
                              const years = Math.trunc(x / 12);
                              const months = x % 12;

                              return (
                                (years === 0 ? '' : ' ' + years + ' years') +
                                (months === 0 ? '' : ' ' + months + ' months')
                              );
                            }}
                          />
                        </Box>

                        <Grid container spacing={2} justify="center" alignItems="center">
                          <Grid item xs={12} sm={7} md={7}>
                            <FormControlLabel
                              control={<Checkbox checked={courier} onChange={handleCourierChange} color="primary" />}
                              label={
                                <Typography fontSize={16} component="span">
                                  {t('translation:pricing:calculator:checkboxLabel')}
                                </Typography>
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={5} md={5}>
                            <Box display="flex" justifyContent="flex-end">
                              <Box mr={2}>
                                <Typography component="div">
                                  <Box fontSize={16} mb={1}>
                                    {t('translation:pricing:calculator:discountLabel')}
                                  </Box>
                                </Typography>

                                <Box className={classes.discountBox} pt={1.5} pb={1.5} pl={5} pr={5}>
                                  {discount}%
                                </Box>
                              </Box>

                              <Box>
                                <Typography component="div">
                                  <Box fontSize={16} fontWeight={600} mb={1}>
                                    {t('translation:pricing:calculator:totalLabel')}
                                  </Box>
                                </Typography>

                                <Box className={classes.totalPriceBox} pt={1.5} pb={1.5} pl={2} pr={2}>
                                  ${totalPrice.toFixed(2)}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Typography component="div">
            <Box mt={4} mb={2} fontSize={18} fontWeight={600}>
              {t('translation:pricing:explanations:title')}
            </Box>
          </Typography>

          <Box>
            <Paper elevation={1}>
              <Box p={2} pr={4}>
                <Grid container spacing={2} direction="row" alignItems="stretch">
                  <Grid item md={4}>
                    <Box p={8}>
                      <img src={capsuleOpened} alt={capsuleOpened} style={{ width: '100%' }} />
                    </Box>
                  </Grid>

                  <Grid item md={8}>
                    <List dense={true}>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:1')}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider light />

                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:2')}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider light />

                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:3')}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider light />

                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:4')}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:5')}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider light />

                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:6')}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider light />

                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography style={{ fontSize: 16 }}>
                              {t('translation:pricing:explanations:list:7')}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Pricing;
