/* eslint-disable no-useless-escape,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useRouter } from '../../hooks/useRouter';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CapsuleStatusPayment from './components/CapsuleStatusPayment';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ChipInput from 'material-ui-chip-input';
import TelegramIcon from '@material-ui/icons/Telegram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import { convertFromServerDate, dateToServerFormat } from '../../helpers/converter';
import * as ROUTES from '../../constants/routes';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginRight: 0,
    marginLeft: 0,
  },
  chipsRoot: {
    display: 'flex',
    width: '100%',
  },
  chipsInput: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
  },
}));

const CapsuleEdit = () => {
  const classes = useStyles();
  const router = useRouter();
  const api = useApi();
  const auth = useAuth();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [capsule, setCapsule] = useState({});
  const [capsuleName, setCapsuleName] = useState('');
  const [allowDelete, setAllowDelete] = useState(false);
  const [allowChange, setAllowChange] = useState(false);
  const [hideNames, setHideNames] = useState(false);
  const [activationEvent, setActivationEvent] = useState(1);
  const [actionType, setActionType] = useState(1);
  const [courierService, setCourierService] = useState('dhl');
  const [courierAddress, setCourierAddress] = useState('');
  const [emailActivationCode, setEmailActivationCode] = useState('');
  const [activationDate, setActivationDate] = useState('');
  const [storagePeriodDate, setStoragePeriodDate] = useState(null);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [telegramUsers, setTelegramUsers] = useState([]);
  const [whatsAppUsers, setWhatsAppUsers] = useState([]);
  const [facebookUsers, setFacebookUsers] = useState([]);

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [whatsAppInvalid, setWhatsAppInvalid] = useState(false);

  const capsuleId = router.query.id;

  const handleChangeCapsuleName = (event) => {
    setCapsuleName(event.target.value);
  };

  const trimCapsuleName = (event) => {
    setCapsuleName(event.target.value.trim());
  };

  const handleChangeAllowDelete = (event) => {
    setAllowDelete(event.target.checked);
  };

  const handleChangeAllowChange = (event) => {
    setAllowChange(event.target.checked);
  };

  const handleChangeHideNames = (event) => {
    setHideNames(event.target.checked);
  };

  const handleChangeActivationEvent = (event) => {
    setActivationEvent(Number(event.target.value));
  };

  const handleChangeActionType = (event) => {
    setActionType(Number(event.target.value));
  };

  const handleChangeCourierService = (event) => {
    setCourierService(event.target.value);
  };

  const handleChangeCourierAddress = (event) => {
    setCourierAddress(event.target.value);
  };

  const trimCourierAddress = (event) => {
    setCourierAddress(event.target.value.trim());
  };

  const handleChangeActivationDate = (date) => {
    setActivationDate(date);
  };

  const handleEmailAddressesAdd = (chip) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(chip).toLowerCase())) {
      setEmailInvalid(false);
      setEmailAddresses([...emailAddresses, chip]);
    } else {
      setEmailInvalid(true);
    }
  };

  const handleTelegramUsersAdd = (chip) => {
    setTelegramUsers([...telegramUsers, chip]);
  };

  const handleWhatsAppUsersAdd = (chip) => {
    if (/[\d\+\-]/.test(chip)) {
      setWhatsAppInvalid(false);
      setWhatsAppUsers([...whatsAppUsers, chip]);
    } else {
      setWhatsAppInvalid(true);
    }
  };

  const handleFacebookUsersAdd = (chip) => {
    setFacebookUsers([...facebookUsers, chip]);
  };

  const handleDeleteAddresses = (chip) => {
    setEmailAddresses(emailAddresses.filter((item) => item !== chip));
  };

  const handleDeleteTelegramUsers = (chip) => {
    setTelegramUsers(telegramUsers.filter((item) => item !== chip));
  };

  const handleDeleteWhatsAppUsers = (chip) => {
    setWhatsAppUsers(whatsAppUsers.filter((item) => item !== chip));
  };

  const handleDeleteFacebookUsers = (chip) => {
    setFacebookUsers(facebookUsers.filter((item) => item !== chip));
  };

  const notValidDate = (date) => {
    if (date instanceof Date) {
      if (moment(date).isBefore(new Date()) || moment(date).isAfter(storagePeriodDate)) {
        return true;
      }
      return isNaN(date);
    }
  };

  const init = () => {
    setLoading(true);

    api
      .getCapsule(capsuleId)
      .then((response) => {
        setCapsule(response.data);

        setCapsuleName(response.data.capsuleName);
        setAllowDelete(!Boolean(response.data.allowDelete));
        setAllowChange(!Boolean(response.data.allowChange));
        setHideNames(Boolean(response.data.hideNames));
        setActivationEvent(response.data.activationEvent);
        setActivationDate(convertFromServerDate(response.data.activationDate));
        setActionType(response.data.actionType);
        setCourierService(response.data.courierService);
        setCourierAddress(response.data.courierAddress);
        setEmailActivationCode(response.data.emailActivationCode);
        setEmailAddresses(response.data.addrEmail ? response.data.addrEmail.split(',') : []);
        setTelegramUsers(response.data.addrTelegram ? response.data.addrTelegram.split(',') : []);
        setWhatsAppUsers(response.data.addrWhatsApp ? response.data.addrWhatsApp.split(',') : []);
        setFacebookUsers(response.data.addrFacebook ? response.data.addrFacebook.split(',') : []);

        setStoragePeriodDate(moment(new Date()).add(response.data.storePeriod, 'months').format('DD MMMM yyyy'));
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshCapsuleState = () => {
    setCapsule({
      ...capsule,
      capsuleName,
      capsuleId,
      allowDelete: allowDelete ? 0 : 1,
      allowChange: allowChange ? 0 : 1,
      hideNames: hideNames ? 1 : 0,
      activationEvent,
      activationDate: dateToServerFormat(activationDate),
      emailActivationCode,
      actionType,
      courierService,
      courierAddress,
      addrEmail: emailAddresses.toString(),
      addrTelegram: telegramUsers.toString(),
      addrWhatsApp: whatsAppUsers.toString(),
      addrFacebook: facebookUsers.toString(),
    });
  };

  const handleSubmit = () => {
    setUpdating(true);

    api
      .updateCapsule(capsule)
      .then(() => {
        router.history.push(ROUTES.USER_CAPSULES);
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  useEffect(() => {
    if (auth.isReady) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    refreshCapsuleState();
  }, [
    capsuleName,
    allowDelete,
    allowChange,
    hideNames,
    activationEvent,
    activationDate,
    emailActivationCode,
    actionType,
    courierService,
    courierAddress,
    emailAddresses,
    telegramUsers,
    whatsAppUsers,
    facebookUsers,
  ]);

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={2}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link component={RouterLink} to={'/capsules'} color="inherit" style={{ fontSize: 20 }}>
            {t('translation:capsules:title')}
          </Link>

          <Typography color="textPrimary" component="div">
            <Box fontSize={20} fontWeight={600}>
              {t('translation:capsules:edit:title')}
            </Box>
          </Typography>
        </Breadcrumbs>
      </Box>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={2}>
              <Box p={4}>
                <Box mb={5}>
                  <TextField
                    value={capsuleName}
                    inputProps={{
                      maxLength: 250,
                    }}
                    onChange={handleChangeCapsuleName}
                    onBlur={trimCapsuleName}
                    label={t('translation:capsules:edit:capsuleName')}
                    fullWidth
                  />
                </Box>

                <Box mb={5}>
                  {capsule.status === 'P' ? (
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex">
                        <CapsuleStatusPayment
                          status={capsule.status}
                          date={moment
                            .unix(capsule.timeCreated)
                            .add(capsule.storePeriod, 'months')
                            .format('D MMMM YYYY')}
                        />
                      </Box>

                      <Box>
                        <Button
                          variant="text"
                          style={{ color: '#0064C2', fontWeight: 600 }}
                          component={RouterLink}
                          to={'/prolong/' + capsuleId}
                        >
                          {t('translation:capsuleCardComplete:P')}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="space-between">
                      <Box display="flex">
                        <CapsuleStatusPayment status={capsule.status} date={moment.unix(capsule.timeCreated)} />
                      </Box>

                      <Box>
                        {capsule.status === 'P' ? (
                          <Button
                            style={{ color: '#0064C2', fontWeight: 600 }}
                            component={RouterLink}
                            to={'/prolong/' + capsuleId}
                          >
                            Prolong storage period
                          </Button>
                        ) : capsule.status === 'N' ? (
                          <Button
                            style={{ color: '#0064C2', fontWeight: 600 }}
                            component={RouterLink}
                            to={'/pay/' + capsuleId}
                          >
                            Pay Now
                          </Button>
                        ) : null}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box mb={5}>
                  <Typography component="div">
                    <Box fontSize={18} fontWeight={600} mb={4}>
                      {t('translation:capsules:edit:settings:title')}
                    </Box>
                  </Typography>

                  <Box mb={5}>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={allowDelete} onChange={handleChangeAllowDelete} color="primary" />
                          }
                          label={t('translation:capsules:edit:settings:option1Label')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={allowChange} onChange={handleChangeAllowChange} color="primary" />
                          }
                          label={t('translation:capsules:edit:settings:option2Label')}
                        />
                        <FormControlLabel
                          control={<Checkbox checked={hideNames} onChange={handleChangeHideNames} color="primary" />}
                          label={t('translation:capsules:edit:settings:option3Label')}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>

                  <Divider light />
                </Box>

                <Box>
                  <Typography component="div">
                    <Box fontSize={18} fontWeight={600} mb={4}>
                      {t('translation:capsules:edit:extract:title')}
                    </Box>
                  </Typography>

                  <Box mb={5}>
                    <FormControl component="fieldset">
                      <RadioGroup value={activationEvent} onChange={handleChangeActivationEvent}>
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:extract:option1Label')}
                        />
                        {activationEvent === 1 ? (
                          <Box ml={4} mt={1} mb={1}>
                            <Typography component="div">
                              <Box fontSize={16} fontWeight={600}>
                                {storagePeriodDate}
                              </Box>
                            </Typography>
                          </Box>
                        ) : null}
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:extract:option2Label')}
                        />

                        {activationEvent === 2 ? (
                          <Box ml={4} mt={1} mb={2} maxWidth={165}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                variant="inline"
                                format="dd/MM/yyyy"
                                value={activationDate}
                                minDate={moment(new Date())}
                                maxDate={storagePeriodDate}
                                onChange={handleChangeActivationDate}
                                InputAdornmentProps={{ position: 'start' }}
                              />
                            </MuiPickersUtilsProvider>
                          </Box>
                        ) : null}

                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:extract:option3Label')}
                        />

                        {activationEvent === 3 ? (
                          <Box ml={4} display="flex" alignItems="center">
                            <Typography component="div">
                              <Box fontSize={18} fontWeight={600} mr={2}>
                                {emailActivationCode}
                              </Box>
                            </Typography>

                            <Box>
                              <CopyToClipboard text={emailActivationCode}>
                                <IconButton style={{ color: '#2E3542' }}>
                                  <FileCopyOutlinedIcon />
                                </IconButton>
                              </CopyToClipboard>

                              <IconButton style={{ color: '#2E3542' }}>
                                <GetAppOutlinedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box>
                  <Typography component="div">
                    <Box fontSize={18} fontWeight={600} mb={4}>
                      {t('translation:capsules:edit:destination:title')}
                    </Box>
                  </Typography>

                  <Box mb={5}>
                    <FormControl component="fieldset">
                      <RadioGroup value={actionType} onChange={handleChangeActionType}>
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:destination:option1.label')}
                        />

                        {actionType === 1 ? (
                          <Box ml={4} mt={2} mb={2}>
                            <Box mb={2} display="flex" alignItems="center">
                              <Box mr={1}>
                                <EmailOutlinedIcon />
                              </Box>

                              <ChipInput
                                placeholder={t('translation:capsules:edit:destination:option1.emailPlaceholder')}
                                name="emailAddresses"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    root: classes.chipsRoot,
                                    input: classes.chipsInput,
                                  },
                                }}
                                disableUnderline
                                helperText={
                                  emailInvalid && (
                                    <span style={{ color: '#E41139' }}>
                                      {t('translation:capsules:edit:destination:option1.emailInvalid')}
                                    </span>
                                  )
                                }
                                newChipKeyCodes={[13, 32, 188]}
                                blurBehavior={'add'}
                                value={emailAddresses}
                                onUpdateInput={() => setEmailInvalid(false)}
                                onAdd={(chip) => handleEmailAddressesAdd(chip)}
                                onDelete={(chip) => handleDeleteAddresses(chip)}
                              />
                            </Box>

                            <Box mb={2} display="flex" alignItems="center">
                              <Box mr={1}>
                                <TelegramIcon />
                              </Box>
                              <ChipInput
                                placeholder={t('translation:capsules:edit:destination:option1.telegramPlaceholder')}
                                value={telegramUsers}
                                name="telegramUsers"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    root: classes.chipsRoot,
                                    input: classes.chipsInput,
                                  },
                                }}
                                disableUnderline
                                newChipKeyCodes={[13, 32, 188]}
                                blurBehavior={'add'}
                                onAdd={(chip) => handleTelegramUsersAdd(chip)}
                                onDelete={(chip) => handleDeleteTelegramUsers(chip)}
                              />
                            </Box>

                            <Box mb={2} display="flex" alignItems="center">
                              <Box mr={1}>
                                <WhatsAppIcon />
                              </Box>
                              <ChipInput
                                placeholder={t('translation:capsules:edit:destination:option1.whatsAppPlaceholder')}
                                value={whatsAppUsers}
                                name="whatsAppUsers"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    root: classes.chipsRoot,
                                    input: classes.chipsInput,
                                  },
                                }}
                                disableUnderline
                                newChipKeyCodes={[13, 32, 188]}
                                blurBehavior={'add'}
                                helperText={
                                  whatsAppInvalid && (
                                    <span style={{ color: '#E41139' }}>
                                      {t('translation:capsules:edit:destination:option1.whatsAppInvalid')}
                                    </span>
                                  )
                                }
                                onUpdateInput={() => setWhatsAppInvalid(false)}
                                onAdd={(chip) => handleWhatsAppUsersAdd(chip)}
                                onDelete={(chip) => handleDeleteWhatsAppUsers(chip)}
                              />
                            </Box>

                            <Box mb={2} display="flex" alignItems="center">
                              <Box mr={1}>
                                <FacebookIcon />
                              </Box>

                              <ChipInput
                                placeholder={t('translation:capsules:edit:destination:option1.facebookPlaceholder')}
                                value={facebookUsers}
                                name="facebookUsers"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    root: classes.chipsRoot,
                                    input: classes.chipsInput,
                                  },
                                }}
                                disableUnderline
                                newChipKeyCodes={[13, 32, 188]}
                                blurBehavior={'add'}
                                onAdd={(chip) => handleFacebookUsersAdd(chip)}
                                onDelete={(chip) => handleDeleteFacebookUsers(chip)}
                              />
                            </Box>
                          </Box>
                        ) : null}

                        <FormControlLabel
                          value={5}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:destination:option2:label')}
                        />

                        {actionType === 5 ? (
                          <Box mt={2} mb={2} ml={4}>
                            <Typography component="div">
                              <Box fontSize={16} fontWeight={600} mb={2}>
                                {t('translation:capsules:edit:destination:option2:title')}
                              </Box>
                            </Typography>

                            <FormControl component="fieldset">
                              <RadioGroup value={courierService} onChange={handleChangeCourierService}>
                                <FormControlLabel
                                  value={'dhl'}
                                  control={<Radio color="primary" />}
                                  label={t('translation:capsules:edit:destination:option2:option1Label')}
                                />
                                <FormControlLabel
                                  value={'fedex'}
                                  control={<Radio color="primary" />}
                                  label={t('translation:capsules:edit:destination:option2:option2Label')}
                                />
                                <FormControlLabel
                                  value={'ups'}
                                  control={<Radio color="primary" />}
                                  label={t('translation:capsules:edit:destination:option2:option3Label')}
                                />
                              </RadioGroup>
                            </FormControl>

                            <Box mt={2}>
                              <TextField
                                value={courierAddress}
                                onChange={handleChangeCourierAddress}
                                onBlur={trimCourierAddress}
                                label={t('translation:capsules:edit:destination:option2:deliveryAddress')}
                                fullWidth
                                disabled={hideNames && courierAddress === '**hidden**'}
                              />
                            </Box>
                          </Box>
                        ) : null}

                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:destination:option3Label')}
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label={t('translation:capsules:edit:destination:option4Label')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box mt={8} display="flex" justifyContent="flex-end">
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        router.history.push(ROUTES.USER_CAPSULES);
                      }}
                    >
                      {t('translation:common:cancel')}
                    </Button>
                  </Box>

                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !capsuleName.length ||
                        (actionType === 5 && courierAddress.length < 12) ||
                        notValidDate(activationDate)
                      }
                      size="large"
                      onClick={handleSubmit}
                    >
                      {!isUpdating ? t('translation:common:save') : <CircularProgress color="inherit" size={26} />}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default CapsuleEdit;
