import React from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';

import MainLayout from '../layouts/MainLayout';
import AuthenticationLayout from '../layouts/AuthenticationLayout';
import WizardLayout from '../layouts/WizardLayout';

import Authentication from './Authentication';
import Dashboard from './Dashboard';
import CapsulesList from './Capsules/CapsulesList';
import CapsuleView from './Capsules/CapsuleView';
import CapsuleEdit from './Capsules/CapsuleEdit';
import CreateCapsuleSteps from './CreateCapsule';
import Faq from './Faq';
import Terms from './Terms';
import Profile from './Profile';
import Pricing from './Pricing';
import Support from './Support';
import PrivacyPolicy from './PrivacyPolicy';
import Pay from './Pay';
import PaymentsHistory from './Profile/PaymentsHistory';
import Prolong from './Prolong';
import TopUp from './TopUp';

import { useAuth } from '../hooks/useAuth';
import * as ROUTES from '../constants/routes';
import NotFound from './NotFound';
import { useRouter } from '../hooks/useRouter';

import firebase from 'firebase';

/**
 * Private route HOC component
 * @param Layout
 * @param Container
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PrivateRoute({ layout: Layout, container: Container, ...rest }) {
  const auth = useAuth();
  const router = useRouter();

  firebase.analytics().logEvent('screen_view', { screen_name: router.pathname });

  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.user && auth.user.emailVerified ? (
          <Layout container={<Container />} {...props} />
        ) : auth.user && !auth.user.emailVerified ? (
          <Redirect to={ROUTES.AUTH_VERIFY_PLEASE} />
        ) : (
          <Redirect to={ROUTES.AUTH_SIGN_IN} />
        );
      }}
    />
  );
}

/**
 * Public Route HOC component
 * @param Layout
 * @param Container
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function PublicRoute({ layout: Layout, container: Container, ...rest }) {
  const auth = useAuth();
  const router = useRouter();

  firebase.analytics().logEvent('screen_view', { screen_name: router.pathname });

  return (
    <Route
      {...rest}
      render={(props) => {
        return auth.user && auth.user.emailVerified ? (
          <Redirect to={ROUTES.ROOT} />
        ) : (
          <Layout container={<Container />} {...props} />
        );
      }}
    />
  );
}

/**
 * Application routing main component
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {
  const auth = useAuth();

  return auth.isLoading ? (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <CircularProgress color="inherit" size={40} />
    </Box>
  ) : (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path={[ROUTES.ROOT, ROUTES.USER_DASHBOARD]} layout={MainLayout} container={Dashboard} />
        <PrivateRoute path={ROUTES.USER_CAPSULE_EDIT} layout={MainLayout} container={CapsuleEdit} />
        <PrivateRoute path={ROUTES.USER_CAPSULE_VIEW} layout={MainLayout} container={CapsuleView} />
        <PrivateRoute path={ROUTES.USER_CAPSULES} layout={MainLayout} container={CapsulesList} />
        <PrivateRoute path={ROUTES.USER_PROFILE_PAYMENTS_HISTORY} layout={MainLayout} container={PaymentsHistory} />
        <PrivateRoute path={ROUTES.USER_PROFILE_TOP_UP_BALANCE} layout={WizardLayout} container={TopUp} />
        <PrivateRoute path={ROUTES.USER_PROFILE} layout={MainLayout} container={Profile} />
        <PrivateRoute path={ROUTES.USER_PRICING} layout={MainLayout} container={Pricing} />
        <PrivateRoute path={ROUTES.USER_FAQ} layout={MainLayout} container={Faq} />
        <PrivateRoute path={ROUTES.USER_SUPPORT} layout={MainLayout} container={Support} />
        <PrivateRoute path={ROUTES.USER_PRIVACY_POLICY} layout={MainLayout} container={PrivacyPolicy} />
        <PrivateRoute path={ROUTES.USER_TERMS_OF_SERVICE} layout={MainLayout} container={Terms} />
        <PrivateRoute path={ROUTES.USER_CREATE_CAPSULE_CONTINUE} layout={WizardLayout} container={CreateCapsuleSteps} />
        <PrivateRoute path={ROUTES.USER_CREATE_CAPSULE} layout={WizardLayout} container={CreateCapsuleSteps} />
        <PrivateRoute path={ROUTES.USER_PAY} layout={WizardLayout} container={Pay} />
        <PrivateRoute path={ROUTES.USER_CAPSULE_PROLONG} layout={WizardLayout} container={Prolong} />
        <PublicRoute path={ROUTES.AUTH} layout={AuthenticationLayout} container={Authentication} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
