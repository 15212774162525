import React, { Fragment } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import capsuleImgDraftToOriginal from '../assets/capsule-draft-to-original.png';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import List from '@material-ui/core/List';
import { useTranslation } from 'react-i18next';

const DialogContentStyled = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
}))(DialogContent);

const SealCapsuleDialog = (props) => {
  const [checked, setChecked] = React.useState(false);
  const { open, onClose, onSubmit, settings } = props;
  const { t } = useTranslation();

  const handleChange = (event) => {
    setChecked(!checked);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onEnter={() => {
          setChecked(false);
        }}
      >
        <DialogContentStyled>
          <Box display="flex" justifyContent="center" mt={4} mb={4}>
            <img src={capsuleImgDraftToOriginal} alt={capsuleImgDraftToOriginal} style={{ maxWidth: '100%' }} />
          </Box>

          <Box mt={4} textAlign="center">
            <Typography style={{ fontWeight: 500 }}>{t('translation:createCapsule:sealDialog:title')}</Typography>
            <Typography style={{ fontWeight: 400 }}>{t('translation:createCapsule:sealDialog:subtitle')}</Typography>
          </Box>

          <Box mt={4} textAlign="left">
            <List dense={false}>
              {!settings.allowDelete && (
                <ListItem>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('translation:createCapsule:sealDialog:text1')} />
                </ListItem>
              )}

              {settings.allowDelete && (
                <ListItem>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('translation:createCapsule:sealDialog:text2')} />
                </ListItem>
              )}

              {!settings.allowChange && (
                <ListItem>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('translation:createCapsule:sealDialog:text3')} />
                </ListItem>
              )}

              {settings.allowChange && (
                <ListItem>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('translation:createCapsule:sealDialog:text4')} />
                </ListItem>
              )}

              {settings.hideNames && (
                <ListItem>
                  <ListItemIcon>
                    <InfoOutlinedIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('translation:createCapsule:sealDialog:text5')} />
                </ListItem>
              )}

              <ListItem>
                <FormControlLabel
                  control={<Checkbox size={'small'} checked={checked} onChange={handleChange} name="confirmation" />}
                  label={
                    <p style={{ fontSize: 14, textAlign: 'left' }}>
                      {t('translation:createCapsule:sealDialog:checkboxLabel')}
                    </p>
                  }
                />
              </ListItem>
            </List>
          </Box>

          <Box mt={4} textAlign="center">

          </Box>

          <Box mt={4} mb={4} textAlign="center">
            {/*<Grid container>*/}
            {/*  <Grid item xs={12}></Grid>*/}
            {/*  <Grid item xs={12}></Grid>*/}
            {/*</Grid>*/}
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={onClose}>
              {t('translation:common:cancel')}
            </Button>

            <Button variant="contained" disabled={!checked} color="primary" size="large" onClick={onSubmit}>
              {t('translation:common:seal')}
            </Button>
          </Box>
        </DialogContentStyled>
      </Dialog>
    </Fragment>
  );
};

export default SealCapsuleDialog;
