import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Radio from '@material-ui/core/Radio';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

import TooltipIcon from '../../components/TooltipIcon';
import { useSteps } from '../../hooks/useSteps';
import Button from '@material-ui/core/Button';
import { useApi } from '../../hooks/useApi';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';
// import { makeStyles } from '@material-ui/styles';

// const useStyle = makeStyles((theme) => ({
//   '.MuiInputBase-root': {
//     fontWeight: 600
//   }
// }));

const dateToServerFormat = (date) => {
  return moment(date).format('DDMMYYYY');
};

const dateFromServerFormat = (date) => {
  return moment()
    .set('date', date.slice(0, 2))
    .set('month', Number(date.slice(2, 4)) - 1)
    .set('year', date.slice(4, 8))
    .format('DD MMMM yyyy');
};

/**
 * Capsule creation: Step 3
 * @returns {JSX.Element}
 * @constructor
 */
export const CreateCapsuleStepExtract = (props) => {
  const api = useApi();
  const { t } = useTranslation();
  const { capsule, setCapsule } = useSteps();

  const maxDate = moment(new Date()).add(capsule.storePeriod, 'months');
  const defaultDate = maxDate.format('DD MMMM yyyy');
  const currentDate = moment(new Date());

  const [activationEvent, setActivationEvent] = useState(capsule.activationEvent || 1);
  const [activationDate, setActivationDate] = useState(
    capsule.activationDate ? dateFromServerFormat(capsule.activationDate) : defaultDate
  );
  const [emailActivationCode] = useState(capsule.emailActivationCode);
  const [snackbarOpened, setSnackbarOpened] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpened(false);
  };

  const handleChangeDate = (date) => {
    setActivationDate(date);
  };

  const handleChangeActivationEvent = (event) => {
    setActivationEvent(Number(event.target.value));

    if (event.target.value === '1') {
      setActivationDate(defaultDate);
    } else {
      setActivationDate(activationDate);
    }
  };

  const saveByteArray = (function () {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    return function (data, name) {
      const blob = new Blob(data, { type: 'text/plain' }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const getFile = () => {
    api
      .getCodewordFile({
        capsuleId: capsule.capsuleId,
        fileFormat: 'TXT',
        createdDateFormat: 'dd-MM-yyyy HH:mm:ss',
        nowDateFormat: 'dd-MM-yyyy HH:mm:ss',
      })
      .then((response) => {
        saveByteArray([response.data], 'codeword.txt');
      });
  };

  const notValidDate = (date) => {
    if (date instanceof Date) {
      if (moment(date).isBefore(currentDate) || moment(date).isAfter(maxDate)) {
        return true;
      }
      return isNaN(date);
    }
  };

  useEffect(() => {
    setCapsule({
      ...capsule,
      activationEvent,
      emailActivationCode,
      activationDate: dateToServerFormat(activationDate),
      stepCover: 3,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationEvent, emailActivationCode, activationDate]);

  useEffect(() => {
    firebase.analytics().logEvent('screen_view', { screen_name: '/create-capsule/open-time' });
  }, []);

  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12} sm={12}>
          <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
            {t('translation:createCapsule:capsuleExtractStep:title')}{' '}
            <TooltipIcon title={t('translation:createCapsule:capsuleExtractStep:titleTooltip')} placement="top" />
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup value={activationEvent} onChange={handleChangeActivationEvent}>
              <FormControlLabel
                style={{ marginBottom: 8 }}
                value={1}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleExtractStep:option1Label')}
                  </Typography>
                }
              />

              {activationEvent === 1 && (
                <Box mt={2} ml={4}>
                  <Typography component="h2" variant="h5" style={{ fontWeight: 600, marginBottom: '24px' }}>
                    {defaultDate}
                  </Typography>
                </Box>
              )}

              <FormControlLabel
                style={{ marginBottom: 8 }}
                value={2}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleExtractStep:option2Label')}
                  </Typography>
                }
              />

              {activationEvent === 2 && (
                <Box ml={4} mt={2} mb={3} maxWidth={195}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      value={activationDate}
                      minDate={currentDate}
                      maxDate={defaultDate}
                      onChange={handleChangeDate}
                      InputProps={{ style: { fontSize: 24 } }}
                      InputAdornmentProps={{ position: 'start' }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              )}

              <FormControlLabel
                style={{ marginBottom: 8 }}
                value={3}
                control={<Radio color="primary" />}
                label={
                  <Typography style={{ fontSize: 24 }}>
                    {t('translation:createCapsule:capsuleExtractStep:option3Label')}
                  </Typography>
                }
              />

              {activationEvent === 3 && (
                <Box mb={3} ml={4} mt={1}>
                  <Typography component="h2" variant="h5" style={{ fontWeight: 600 }}>
                    {emailActivationCode}

                    <CopyToClipboard text={emailActivationCode} onCopy={() => setSnackbarOpened(true)}>
                      <IconButton>
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </CopyToClipboard>

                    <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      open={snackbarOpened}
                      autoHideDuration={3000}
                      onClose={handleCloseSnackbar}
                    >
                      <Alert onClose={handleCloseSnackbar} severity="success">
                        Activation code has been successfully copied to clipboard!
                      </Alert>
                    </Snackbar>

                    <IconButton onClick={getFile}>
                      <GetAppOutlinedIcon />
                    </IconButton>
                  </Typography>

                  <Box style={{ maxWidth: 400 }}>
                    <Typography component="p" style={{ fontSize: 16, color: '#B00020', fontWeight: 300 }}>
                      {t('translation:createCapsule:capsuleExtractStep:codewordDescription')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </RadioGroup>
          </FormControl>

          <Box mt={8}>
            <Button variant="outlined" size="large" style={{ marginRight: 16 }} onClick={props.handleBack}>
              {t('translation:common:back')}
            </Button>

            <Button
              disabled={notValidDate(activationDate)}
              variant="contained"
              color="primary"
              size="large"
              onClick={props.handleNext}
            >
              {t('translation:common:next')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
