import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

import { useSteps } from '../hooks/useSteps';
import Logo from '../components/Logo';
import clsx from 'clsx';

import stepsCover1 from '../assets/steps-cover-1.png';
import stepsCover2 from '../assets/steps-cover-2.png';
import stepsCover3 from '../assets/steps-cover-3.png';
import stepsCover4 from '../assets/steps-cover-4.png';
import stepsCover51 from '../assets/steps-cover-5-1.png';
import stepsCover52 from '../assets/steps-cover-5-2.png';
import stepsCover6 from '../assets/steps-cover-6.png';
import stepsCover7 from '../assets/steps-cover-7.png';
import stepsCover81 from '../assets/steps-cover-8-1.png';
import stepsCover82 from '../assets/steps-cover-8-2.png';
import stepsCover83 from '../assets/steps-cover-8-3.png';
import stepsCover9 from '../assets/steps-cover-9.png';
import { useRouter } from '../hooks/useRouter';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  cover: {
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  cover1: {
    backgroundImage: `url(${stepsCover1})`,
  },
  cover2: {
    backgroundImage: `url(${stepsCover2})`,
  },
  cover3: {
    backgroundImage: `url(${stepsCover3})`,
  },
  cover4: {
    backgroundImage: `url(${stepsCover4})`,
  },
  cover51: {
    backgroundImage: `url(${stepsCover51})`,
  },
  cover52: {
    backgroundImage: `url(${stepsCover52})`,
  },
  cover6: {
    backgroundImage: `url(${stepsCover6})`,
  },
  cover7: {
    backgroundImage: `url(${stepsCover7})`,
  },
  cover81: {
    backgroundImage: `url(${stepsCover81})`,
  },
  cover82: {
    backgroundImage: `url(${stepsCover82})`,
  },
  cover83: {
    backgroundImage: `url(${stepsCover83})`,
  },
  cover9: {
    backgroundImage: `url(${stepsCover9})`,
  },
  header: {
    display: 'flex',
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 2),
    },
  },
  backgroundPos: {
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
  },
}));

const WizardLayout = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const history = useHistory();
  const { INITIAL_STATE, capsule, setCapsule } = useSteps();

  const handleCloseCreateCapsuleLayout = () => {
    setCapsule(INITIAL_STATE);
    const url = router.location.state ? router.location.state.from : null;
    url ? history.push(url) : router.history.goBack();
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box className={classes.header} display="flex" justifyContent="space-between">
          <Box>
            <Logo type="standard" />
          </Box>

          <Box textAlign="right">
            <Tooltip title={t('translation:createCapsule:closeButtonTooltip')} placement="bottom">
              <IconButton onClick={handleCloseCreateCapsuleLayout}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Container maxWidth="md">{props.container}</Container>
        </Box>
      </Grid>

      <Grid
        item
        xs={false}
        sm={false}
        md={4}
        lg={4}
        className={clsx(classes.backgroundPos, classes['cover' + capsule.stepCover])}
      />
    </Grid>
  );
};

export default WizardLayout;
