import React from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CoreVersionWidget = (props) => {
  const { coreVersion } = props;
  const { t } = useTranslation();

  return (
    <Paper elevation={0}>
      <Box p={2} pl={0} textAlign="left">
        <Typography component="div">
          <Box fontSize={16}>
            {t('translation:dashboard:coreVersion')}: {coreVersion}
          </Box>
        </Typography>
      </Box>
    </Paper>
  );
};

export default CoreVersionWidget;
