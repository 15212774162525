import { Base64 } from 'js-base64';
import * as forge from 'node-forge';

export const generateRsaKeys = async () => {
  let keys = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256'
    },
    true,
    ['encrypt', 'decrypt']
  );
  const publicKey = await crypto.subtle.exportKey('jwk', keys.publicKey);
  const modulus = base64UrlDecode(publicKey.n);
  const exponent = base64UrlDecode(publicKey.e);

  const privateKey = await crypto.subtle.exportKey('pkcs8', keys.privateKey);

  return {
    privateKey,
    publicKey: btoa(String.fromCharCode(...new Uint8Array([...modulus, ...exponent])))
  };
};

function base64UrlDecode(str) {
  str = atob(str.replace(/-/g, '+').replace(/_/g, '/'));
  const buffer = new Array(str.length);
  for (let i = 0; i < str.length; ++i) {
    buffer[i] = str.charCodeAt(i);
  }
  return buffer;
}

export const readFileAsBuffer = (advancedFile) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onerror = reject;
    fileReader.onload = function () {
      resolve(fileReader.result);
    };

    fileReader.readAsArrayBuffer(advancedFile.file);
  });
};

export const decryptAesKey = (exportedPrivateKey, aesKeyEncoded) => {
  const buffer = new forge.util.ByteBuffer(exportedPrivateKey);
  const asn1 = forge.asn1.fromDer(buffer);
  const privateKey = forge.pki.privateKeyFromAsn1(asn1);

  const decryptedKey = privateKey.decrypt(atob(aesKeyEncoded), 'RSA-OAEP');
  const aesKey = btoa(decryptedKey.slice(0, 32));
  const aesIv = btoa(decryptedKey.slice(32, decryptedKey.length));

  return {
    aesKey,
    aesIv,
  };
};

export const importAesKey = async (aesKey) => {
  return await window.crypto.subtle.importKey(
    'raw',
    Base64.toUint8Array(aesKey),
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['encrypt', 'decrypt']
  );
};

export const encryptFileWithAes = async (aesCryptoKey, aesIv, file) => {
  return await window.crypto.subtle.encrypt(
    {
      name: 'AES-CBC',
      iv: Base64.toUint8Array(aesIv),
    },
    aesCryptoKey,
    file
  );
};
